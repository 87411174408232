<template>
  <div class="w-full h-full p-2">
    <div v-if="!rp" class="flex justify-center mt-[4.5rem]"><Spinner size="w-32"/></div>
    <div v-if="rp" class="relative">
      <div class="flex">
        <div class="grow mr-4">
          <div class="xl:grid xl:gap-4">
            <div>
              <RPTitle :rp="rp"
                :readonly="rp[ RP_FIELD.STATE ] !== RP_STATE.OPEN"
                :key="componentKey"
                @changeItem="changeRPField"/>
              <RPDescription :rp="rp"
                :readonly="rp[ RP_FIELD.STATE ] !== RP_STATE.OPEN"
                :key="descriptionKey"
                @changeItem="changeRPField"
                @update="updateRPLastActivity"
                @changeIsIsNotItem="changeIsIsNotItem"
                @changeIsIsNotList="changeIsIsNotList"
                @removeIsIsNotItem="removeIsIsNotItem"/>
            </div>
          </div>
          <RPSecuringActions ref="securingActionsRef"
            :id_rp="rp[ RP_FIELD.ID ]"
            :securingActions="rp[ RP_FIELD.RP_SECURING_ACTIONS ]"
            :users="rp[ RP_FIELD.RP_USERS ]"
            :rp_impact_studies="rp.rp_impact_studies"
            :detectedDate="rp.detected_date"
            :readonly="rp[ RP_FIELD.STATE ] !== RP_STATE.OPEN"
            @changeList="changeSecuringActionsList"
            @changeItem="changeSecuringActionItem"
            @removeItem="removeSecuringActionItem"/>
          <RPCauses ref="causesRef" :id_rp="rp[ RP_FIELD.ID ]"
            :rp="rp"
            :causes="filterDisabledCauses( rp[ RP_FIELD.RP_CAUSES ] )"
            :fileName="'RP_' + rp.index + '_Ishikawa'"
            :readonly="rp[ RP_FIELD.STATE ] !== RP_STATE.OPEN"
            @changeList="changeCausesList"
            @changeItem="changeCauseItem"
            @removeItem="removeCauseItem"
            @change5Why="change5Why"
            @changeMatrix="changeMatrix"
            @changeMatrixCell="changeMatrixCell"
            @changeMatrixColumn="changeMatrixColumn"/>
          <RPActions ref="actionsRef" :id_rp="rp[ RP_FIELD.ID ]"
            :key="actionsKey"
            :causes="filterDisabledCauses( rp[ RP_FIELD.RP_CAUSES ] )"
            :users="rp[ RP_FIELD.RP_USERS ]"
            :actions="rp[ RP_FIELD.RP_ACTIONS ]"
            :readonly="rp[ RP_FIELD.STATE ] !== RP_STATE.OPEN"
            @changeList="changeActionsList"
            @changeItem="changeActionItem"
            @removeItem="removeActionItem"/>
          <RPCapitalisingActions ref="capitalisingActionsRef"
            :id_rp="rp[ RP_FIELD.ID ]"
            :key="actionsKey"
            :capitalisingActions="rp[ RP_FIELD.RP_CAPITALISING_ACTIONS ]"
            :users="rp[ RP_FIELD.RP_USERS ]"
            :actions="filterEfficientActions( rp[ RP_FIELD.RP_ACTIONS ] )"
            :readonly="rp[ RP_FIELD.STATE ] !== RP_STATE.OPEN"
            @changeList="changeCapitalisingActionsList"
            @changeItem="changeCapitalisingActionItem"
            @removeItem="removeCapitalisingActionItem"/>
          <!-- <RPActivity :history="rp.history" :key="componentKey"/> -->
        </div>
        <div class="flex-none w-[25.5rem] space-y-6">
          <RPRightBar :rp="rp"
            :readonly="rp[ RP_FIELD.STATE ] !== RP_STATE.OPEN"
            :key="rightBarKey"
            @changeRP="changeRPField"
            @changeUsers="changeUsersList"/>
          <RPDocuments ref="documentsRef"
            :id_rp="rp[ RP_FIELD.ID ]"
            :key="componentKey"
            :documents="rp[ RP_FIELD.RP_DOCUMENTS ]"
            :readonly="rp[ RP_FIELD.STATE ] !== RP_STATE.OPEN"
            @changeList="changeDocumentsList"
            @changeItem="changeDocumentItem"
            @removeItem="removeDocumentItem"/>
          <RPNotes ref="notesRef" :id_rp="rp.id"
            :key="componentKey"
            :notes="rp[ RP_FIELD.RP_NOTES ]"
            :readonly="rp[ RP_FIELD.STATE ] !== RP_STATE.OPEN"
            @changeList="changeNotesList"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import store from '@/store';
  import utils from '@/features/utils.js';
  import RPTitle from '@/components/rp/details/RPTitle.vue';
  import RPDescription from '@/components/rp/details/RPDescription.vue';
  import RPSecuringActions from '@/components/rp/securing_actions/RPSecuringActions.vue';
  import RPCauses from '@/components/rp/causes/RPCauses.vue';
  import RPActions from '@/components/rp/actions/RPActions.vue';
  import RPCapitalisingActions from '@/components/rp/capitalising_actions/RPCapitalisingActions.vue';
  import RPRightBar from '@/components/rp/details/RPRightBar.vue';
  import RPDocuments from '@/components/rp/RPDocuments.vue';
  import RPNotes from '@/components/rp/notes/RPNotes.vue';
  // import RPActivity from '@/components/_rp/RPActivity.vue';
  import AXIOS from '@/features/axios.js';
  import Spinner from '@/components/object/SpinnerBasic.vue';
  import API from '@/constants/api.constants';
  import { RP_STATE, RP_FIELD } from '@/constants/rp.constants.js';

  // Re-Render keys
  const componentKey = ref( 0 );
  const descriptionKey = ref( 0 );
  const actionsKey = ref( 0 );
  const securingActionsKey = ref( 0 );
  const rightBarKey = ref( 0 );

  const route = useRoute();
  const rp = ref( undefined );

  const securingActionsRef = ref( null );
  const causesRef = ref( null );
  const actionsRef = ref( null );
  const capitalisingActionsRef = ref( null );
  const documentsRef = ref( null );
  const notesRef = ref( null );

  watch( route, () => {
    getRP();
  } );

  const setLastRPVisitedCookie = () => {
    if ( route.params.id != undefined && route.params.id != null ) {
      store.dispatch( 'setLastRPVisitedURL', '/rp/' + route.params.id );
    }
  };

  const forceRerender = () => {
    componentKey.value += 1;
    descriptionKey.value += 1;
    actionsKey.value += 1;
    securingActionsKey.value += 1;
  };

  const reRenderAllActions = () => {
    actionsKey.value += 1;
    securingActionsKey.value += 1;
  };

  const descriptionReRender = () => {
    descriptionKey.value += 1;
  };

  const filterDisabledCauses = ( causesList ) => {
    if ( causesList != null && Array.isArray( causesList ) && causesList.length > 0 ) {
      return causesList.filter( ( item ) => !item.disabled );
    } else {
      return [];
    }
  };

  const filterEfficientActions = ( actionsList ) => {
    if ( actionsList != null && Array.isArray( actionsList ) && actionsList.length > 0 ) {
      return actionsList.filter( ( item ) => {
        if ( item.action_users != null && Array.isArray ( item.action_users )
          && item.action_users.length > 0 ) {
          for ( let i = 0 ; i < item.action_users.length ; i++ ) {
            let actionUser = item.action_users[ i ];
            if ( actionUser.is_efficient == false || actionUser.is_efficient == 'false' ) {
              return false;
            }
          }
          return true;
        }
        return false;
      } );
    } else {
      return [];
    }
  };

  const removeIsIsNotItem = async ( id ) => {
    if ( id != undefined && id != null ) {
      updateRPLastActivity();
      let index = rp.value[ RP_FIELD.TOOL_IS_ISNOT ].findIndex( ( is_isNot ) => {
        if ( is_isNot.id != undefined && is_isNot.id != null ) {
          return is_isNot.id == id;
        }
        return false;
      } );
      index > -1 ? rp.value[ RP_FIELD.TOOL_IS_ISNOT ].splice( index, 1 ) : undefined;
    }
  };

  const changeIsIsNotItem = async ( id ) => {
    if ( id != undefined && id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.TOOL_IS_ISNOT, id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          let index = rp.value[ RP_FIELD.TOOL_IS_ISNOT ].findIndex( ( is_isNot ) => {
            if ( is_isNot.id != undefined && is_isNot.id != null && is_isNot.id == id ) {
              return true;
            }
            return false;
          } );
          index > -1 ? rp.value[ RP_FIELD.TOOL_IS_ISNOT ].splice( index, 1, response.data ) : undefined;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const getRP = () => {
    if ( route.params.id != undefined && route.params.id != null ) {
      let url = API.get_api( API.API_NAMES.RP_BY_ID, route.params.id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          if ( response.status == '200' && ( response.data.err == undefined || response.data.err == null ) ) {
            rp.value = response.data;
            setLastRPVisitedCookie();

            if ( rp.value ) {
              if ( rp.value[ RP_FIELD.RP_USERS ] ) {
                rp.value[ RP_FIELD.RP_USERS ] = rp.value[ RP_FIELD.RP_USERS ].map( ( element ) => {
                  return { val: element.user.id, text: element.user.fullname };
                } );
              }
              if ( rp.value[ RP_FIELD.RP_SECURING_ACTIONS ] ) {
                rp.value[ RP_FIELD.RP_SECURING_ACTIONS ] = rp.value[ RP_FIELD.RP_SECURING_ACTIONS ].map( ( element ) => {
                  return element.action;
                } );
              }
              if ( rp.value[ RP_FIELD.RP_ACTIONS ] ) {
                rp.value[ RP_FIELD.RP_ACTIONS ] = rp.value[ RP_FIELD.RP_ACTIONS ].map( ( element ) => {
                  return element.action;
                } );
              }
              if ( rp.value[ RP_FIELD.RP_CAPITALISING_ACTIONS ] ) {
                rp.value[ RP_FIELD.RP_CAPITALISING_ACTIONS ] = rp.value[ RP_FIELD.RP_CAPITALISING_ACTIONS ].map( ( element ) => {
                  return element.action;
                } );
              }
              if ( rp.value[ RP_FIELD.RP_CAUSES ] ) {
                rp.value[ RP_FIELD.RP_CAUSES ] = rp.value[ RP_FIELD.RP_CAUSES ].map( ( element ) => {
                  return element.cause;
                } );
              }
              if ( rp.value[ RP_FIELD.RP_DOCUMENTS ] ) {
                rp.value[ RP_FIELD.RP_DOCUMENTS ] = rp.value[ RP_FIELD.RP_DOCUMENTS ].map( ( element ) => {
                  return element.document;
                } );
              }
              if ( rp.value[ RP_FIELD.TOOL_5WHY ] ) {
                rp.value[ RP_FIELD.TOOL_5WHY ] = sort5WhyNodes( rp.value[ RP_FIELD.TOOL_5WHY ] ) ?? undefined;
              }
            }
            forceRerender();
          }
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeRPField = async ( fields ) => {
    if ( route.params.id != undefined && route.params.id != null ) {
      let url = API.get_api( API.API_NAMES.RP_BY_ID, route.params.id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          let newRPData = response.data;
          fields.forEach( ( field ) => {
            switch ( field ) {
            case RP_FIELD.TITLE:
              rp.value[ RP_FIELD.TITLE ] = newRPData[ RP_FIELD.TITLE ] ?? '';
              break;

            case RP_FIELD.PILOT:
              rp.value[ RP_FIELD.PILOT ] = newRPData[ RP_FIELD.PILOT ] ?? null;
              break;

            case RP_FIELD.STATE:
              if ( newRPData[ RP_FIELD.STATE ] != undefined && newRPData[ RP_FIELD.STATE ] != null ) {
                switch ( newRPData[ RP_FIELD.STATE ] ) {
                case RP_STATE.OPEN:
                  rp.value[ RP_FIELD.STATE ] = RP_STATE.OPEN;
                  break;
                case RP_STATE.CLOSE:
                  rp.value[ RP_FIELD.STATE ] = RP_STATE.CLOSE;
                  break;
                case RP_STATE.REOPEN:
                  rp.value[ RP_FIELD.STATE ] = RP_STATE.REOPEN;
                  break;
                }
              }
              break;

            case RP_FIELD.DETECTED_DATE:
              rp.value[ RP_FIELD.DETECTED_DATE ] = newRPData[ RP_FIELD.DETECTED_DATE ] ?? null;
              break;

            case RP_FIELD.TOOL_6W:
              rp.value[ RP_FIELD.TOOL_6W ] = newRPData[ RP_FIELD.TOOL_6W ] ?? null;
              break;

            case RP_FIELD.DESCRIPTION:
              rp.value[ RP_FIELD.DESCRIPTION ] = newRPData[ RP_FIELD.DESCRIPTION ] ?? null;
              descriptionReRender();
              break;

            case RP_FIELD.IS_RECURRENT:
              rp.value[ RP_FIELD.IS_RECURRENT ] = newRPData[ RP_FIELD.IS_RECURRENT ] ?? false;
              break;

            case RP_FIELD.CLIENT_NAME:
              rp.value[ RP_FIELD.CLIENT_NAME ] = newRPData[ RP_FIELD.CLIENT_NAME ] ?? null;
              break;

            case RP_FIELD.CLIENT_NUMBER:
              rp.value[ RP_FIELD.CLIENT_NUMBER ] = newRPData[ RP_FIELD.CLIENT_NUMBER ] ?? null;
              break;
            }
          } );
          newRPData[ RP_FIELD.LAST_ACTIVITY ] ? rp.value[ RP_FIELD.LAST_ACTIVITY ] = newRPData[ RP_FIELD.LAST_ACTIVITY ] : undefined ;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const updateRPLastActivity = () => {
    if ( route.params.id != undefined && route.params.id != null ) {
      let url = API.get_api( API.API_NAMES.RP, route.params.id ) + 'last_modify_date';
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          response.data[ RP_FIELD.LAST_ACTIVITY ] ? rp.value[ RP_FIELD.LAST_ACTIVITY ] = response.data[ RP_FIELD.LAST_ACTIVITY ] : undefined ;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeUsersList = async () => {
    if ( route.params.id != undefined && route.params.id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.RP_USERS, route.params.id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          rp.value[ RP_FIELD.RP_USERS ] = response.data.map( ( element ) => {
            return { val: element.id, text: element.fullname };
          } ) ?? undefined;
          reRenderAllActions();
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeIsIsNotList = async () => {
    if ( route.params.id != undefined && route.params.id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.TOOL_IS_ISNOT, 'by_rp', route.params.id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          rp.value[ RP_FIELD.TOOL_IS_ISNOT ] = response.data;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeSecuringActionsList = async () => {
    if ( route.params.id != undefined && route.params.id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.RP_SECURING_ACTION, route.params.id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          rp.value[ RP_FIELD.RP_SECURING_ACTIONS ] = response.data ?? undefined;
          !securingActionsRef.value.isToggled ? securingActionsRef.value.toggleExpander() : undefined;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeSecuringActionItem = async ( id ) => {
    if ( id != undefined && id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.ACTIONS, id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          let index = rp.value[ RP_FIELD.RP_SECURING_ACTIONS ].findIndex( ( action ) => {
            if ( action.id != undefined && action.id != null && action.id == id ) {
              return true;
            }
            return false;
          } );
          index > -1 ? rp.value[ RP_FIELD.RP_SECURING_ACTIONS ].splice( index, 1, response.data ) : undefined;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const removeSecuringActionItem = async ( id ) => {
    if ( id != undefined && id != null ) {
      updateRPLastActivity();
      let index = rp.value[ RP_FIELD.RP_SECURING_ACTIONS ].findIndex( ( action ) => {
        if ( action.id != undefined && action.id != null ) {
          return action.id == id;
        }
        return false;
      } );
      index > -1 ? rp.value[ RP_FIELD.RP_SECURING_ACTIONS ].splice( index, 1 ) : undefined;
    }
  };

  const changeCausesList = async () => {
    if ( route.params.id != undefined && route.params.id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.RP_CAUSES, route.params.id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          rp.value[ RP_FIELD.RP_CAUSES ] = response.data ? filterDisabledCauses( response.data ) : undefined;
          !causesRef.value.isToggled ? causesRef.value.toggleExpander() : undefined;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeCauseItem = async ( id ) => {
    if ( id != undefined && id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.CAUSES, id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          let index = rp.value[ RP_FIELD.RP_CAUSES ].findIndex( ( action ) => {
            if ( action.id != undefined && action.id != null && action.id == id ) {
              return true;
            }
            return false;
          } );
          index > -1 ? rp.value[ RP_FIELD.RP_CAUSES ].splice( index, 1, response.data ) : undefined;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const removeCauseItem = async ( id ) => {
    if ( id != undefined && id != null ) {
      updateRPLastActivity();
      let index = rp.value[ RP_FIELD.RP_CAUSES ].findIndex( ( action ) => {
        if ( action.id != undefined && action.id != null ) {
          return action.id == id;
        }
        return false;
      } );
      index > -1 ? rp.value[ RP_FIELD.RP_CAUSES ].splice( index, 1 ) : undefined;
    }
  };

  const changeActionsList = async () => {
    if ( route.params.id != undefined && route.params.id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.RP_ACTIONS, route.params.id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          rp.value[ RP_FIELD.RP_ACTIONS ] = response.data ?? null;
          !actionsRef.value.isToggled ? actionsRef.value.toggleExpander() : undefined;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeActionItem = async ( id ) => {
    if ( id != undefined && id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.ACTIONS, id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          let index = rp.value[ RP_FIELD.RP_ACTIONS ].findIndex( ( action ) => {
            if ( action.id != undefined && action.id != null && action.id == id ) {
              return true;
            }
            return false;
          } );
          index > -1 ? rp.value[ RP_FIELD.RP_ACTIONS ].splice( index, 1, response.data ) : undefined;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const removeActionItem = async ( id ) => {
    if ( id != undefined && id != null ) {
      updateRPLastActivity();
      let index = rp.value[ RP_FIELD.RP_ACTIONS ].findIndex( ( action ) => {
        if ( action.id != undefined && action.id != null ) {
          return action.id == id;
        }
        return false;
      } );
      index > -1 ? rp.value[ RP_FIELD.RP_ACTIONS ].splice( index, 1 ) : undefined;
    }
  };

  const changeCapitalisingActionsList = async () => {
    if ( route.params.id != undefined && route.params.id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.RP_CAPITALISING_ACTION, route.params.id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          rp.value[ RP_FIELD.RP_CAPITALISING_ACTIONS ] = response.data ?? null;
          !capitalisingActionsRef.value.isToggled ? capitalisingActionsRef.value.toggleExpander() : undefined;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeCapitalisingActionItem = async ( id ) => {
    if ( id != undefined && id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.ACTIONS, id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          let index = rp.value[ RP_FIELD.RP_CAPITALISING_ACTIONS ].findIndex( ( action ) => {
            if ( action.id != undefined && action.id != null && action.id == id ) {
              return true;
            }
            return false;
          } );
          index > -1 ? rp.value[ RP_FIELD.RP_CAPITALISING_ACTIONS ].splice( index, 1, response.data ) : undefined;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const removeCapitalisingActionItem = async ( id ) => {
    if ( id != undefined && id != null ) {
      updateRPLastActivity();
      let index = rp.value[ RP_FIELD.RP_CAPITALISING_ACTIONS ].findIndex( ( action ) => {
        if ( action.id != undefined && action.id != null ) {
          return action.id == id;
        }
        return false;
      } );
      index > -1 ? rp.value[ RP_FIELD.RP_CAPITALISING_ACTIONS ].splice( index, 1 ) : undefined;
    }
  };

  const changeDocumentsList = async () => {
    if ( route.params.id != undefined && route.params.id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.RP_DOCUMENTS, route.params.id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          rp.value[ RP_FIELD.RP_DOCUMENTS ] = response.data ?? undefined;
          !documentsRef.value.isToggled ? documentsRef.value.toggleExpander() : undefined;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeDocumentItem = async ( id ) => {
    if ( id != undefined && id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.DOCUMENTS, id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          rp.value[ RP_FIELD.RP_DOCUMENTS ].forEach( ( document ) => {
            if ( document.id != undefined && document.id != null && document.id == id ) {
              document = response.data;
            }
          } );
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const removeDocumentItem = async ( id ) => {
    if ( id != undefined && id != null ) {
      updateRPLastActivity();
      let index = rp.value[ RP_FIELD.RP_DOCUMENTS ].findIndex( ( action ) => {
        if ( action.id != undefined && action.id != null ) {
          return action.id == id;
        }
        return false;
      } );
      index > -1 ? rp.value[ RP_FIELD.RP_DOCUMENTS ].splice( index, 1 ) : undefined;
    }
  };

  const changeNotesList = async () => {
    if ( route.params.id != undefined && route.params.id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.RP_NOTES, route.params.id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          rp.value[ RP_FIELD.RP_NOTES ] = response.data ?? undefined;
          !notesRef.value.isToggled ? notesRef.value.toggleExpander() : undefined;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const change5Why = async () => {
    if ( route.params.id != undefined && route.params.id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.TOOL_5WHY, 'by_rp', route.params.id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          rp.value[ RP_FIELD.TOOL_5WHY ] = sort5WhyNodes( response.data ) ?? undefined;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeMatrix = async () => {
    if ( route.params.id != undefined && route.params.id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.TOOL_PRIORITY_MATRIX, 'by_rp', route.params.id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          rp.value[ RP_FIELD.TOOL_PRIORITY_MATRIX ] = response.data ?? undefined;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeMatrixColumn = async ( id ) => {
    if ( id != undefined && id != null ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.TOOL_PRIORITY_MATRIX, id );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          if ( response.status == '200' && response.data ) {
            let newData = response.data;

            rp.value[ RP_FIELD.TOOL_PRIORITY_MATRIX ].every( ( column ) => {
              if ( column.id === id && newData ) {
                newData.weight != null ? column.weight = newData.weight : null;
                newData.is_ascending != null ? column.is_ascending = newData.is_ascending : null;
                newData.label != null ? column.label = newData.label : null;
                column.label_tooltip = newData.label_tooltip ?? null;
                newData.last_activity != null ? column.last_activity = newData.last_activity : null;
                return false;
              }
              return true;
            } );
          }
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeMatrixCell = async ( params ) => {
    if ( route.params.id != undefined &&
      route.params.id != null &&
      params != null &&
      Array.isArray( params ) &&
      params.length >= 2 ) {
      updateRPLastActivity();
      let url = API.get_api( API.API_NAMES.TOOL_PRIORITY_MATRIX, params[ 0 ], 'causes', params[ 1 ] );
      AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );

          if ( response.status == '200'
            && response.data.weight != undefined ) {
            let column = rp.value[ RP_FIELD.TOOL_PRIORITY_MATRIX ].find( ( column ) => column.id == params[ 0 ] );
            if ( column ) {
              let cause = column.priority_matrix_causes.find( ( cause ) => cause.id == params[ 1 ] );
              if ( cause ) {
                cause.weight = response.data.weight;
              }
            }
          }

          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const sort5WhyNodes = ( node ) => {
    if ( node != null && node.children != null && Array.isArray( node.children ) && node.children.length > 0 ) {
      utils.sortListByRegisterDateAscending( node.children );
      node.children.forEach( ( element ) => {
        element = sort5WhyNodes( element );
        return element;
      } );
    }
    return node ?? undefined;
  };

  getRP();
</script>