<template>
  <div class="bg-white shadow shadow-shadowColor rounded group">
    <div class="focus-visible:outline-none rounded">
      <!-- Dropdown menu header -->
      <div @click="toggleExpander()"
        class="flex flex-row content-center space-x-2 px-4 py-2 -mb-3 text-lg rounded hover:bg-tertiary-hover transition duration-200">
        <div class="font-bold">{{ t( 'Notes' ) }}</div>
        <div class="has-tooltip flex">
          <span class='tooltip'>{{ t( 'Notes total' )}}</span>
          <span class="items-center px-1.5 pb-0.5 ml-1 mr-1 font-bold rounded-full bg-gray-500 text-white">
            {{ numberOfNotes }}
          </span>
        </div>
        <button v-if="!readonly" @click.stop="modal.open()"
          class="has-tooltip hover:bg-fourth-hover text-primary text-xxl rounded-full align-center justify-center">
          <font-awesome-icon icon="fa-solid fa-plus" size="xl" class="px-1 py-0"/>
          <span class='tooltip mb-10'>{{ t( 'Add a note' ) }}</span>
        </button>
        <div class="flex flex-row grow gap-3 justify-end">
          <font-awesome-icon v-if="isToggled" icon="fa-solid fa-chevron-right" rotation="90" size="xl"/>
          <font-awesome-icon v-else icon="fa-solid fa-chevron-right" size="xl"/>
        </div>
      </div>
    </div>
    <!-- Dropdown menu body -->
    <Collapse :when="isToggled" class="collapse-class mt-3">
      <div>
        <div v-for="note in sortNotesList" :key="note"
          class="flex px-3 py-1.5">
          <NoteItem :note="note"/>
        </div>
      </div>
    </Collapse>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import store from '@/store';
  import AXIOS from '@/features/axios.js';
  import utils from '@/features/utils.js';
  import API from '@/constants/api.constants';
  import NoteItem from '@/components/rp/notes/RPNoteItem.vue';
  import NewNoteForm from '@/components/rp/notes/NewNoteForm.vue';
  import { useModal } from 'vue-final-modal';
  import Modal from '@/components/modals/ModalBasic.vue';

  const props = defineProps( {
    id_rp: { String, required: true },
    readonly: { Boolean, required: false, default: false },
    notes: { Array, required: true, default: null }
  } );
  const emit = defineEmits( [ 'changeList' ] );

  const { t } = useI18n();
  const isToggled = ref( store.getters.getExpanders.notes ?? true );
  const sortNotesList = computed( () => {
    return utils.sortListByRegisterDateAscending( props.notes ).toReversed();
  } );
  const numberOfNotes = computed( () => {
    let total = 0;
    if ( props.notes != null
      && Array.isArray( props.notes ) ) {
      total = props.notes.length;
    }
    return total;
  } );

  const toggleExpander = () => {
    store.dispatch( 'setExpanders', { notes: !isToggled.value } );
    isToggled.value = store.getters.getExpanders.notes;
  };
  defineExpose( { toggleExpander, isToggled } );

  const changeList = () => {
    emit( 'changeList' );
  };

  const modal = useModal( {
    component: Modal,
    attrs: { header: t( 'Add a note' ) },
    slots: {
      content: {
        component: NewNoteForm,
        attrs: {
          onCancel() {
            modal.close();
          },
          onAddNote( note ) {
            if ( note && note.trim() != '' ) {
              newNote( note );
            }
            modal.close();
          }
        }
      }
    }
  } );

  const newNote = ( note ) => {
    if ( note != undefined && note != null && note != '' ) {
      let url = API.get_api( API.API_NAMES.RP_NOTES + props.id_rp );
      AXIOS.post( url, { note: note, author: store.getters.get_connected_user.id }, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          if ( response.status == '200' ) {
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            changeList();
          }
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };
</script>

<style lang="scss" scoped>
  .has-tooltip:hover .tooltip {
    @apply visible opacity-100 z-50 -mx-1 my-8;
  }
</style>