<template>
  <div class="flex text-tertiary-text mb-20 p-4 gap-6">
    <div class="flex-col min-w-[45rem] max-w-3xl space-y-4">
      <div class="font-bold text-4xl">{{ t('Settings') }}</div>

      <!-- Change max uploaded file size -->
      <div class="input-element">
        <label class="input-label">
          {{ t('Max size of images and documents') }}
        </label>
        <input class="settings-label input-field"
          id="max-file-size-mo"
          type="number"
          v-model.number.lazy="params.max_file_size_mo"
          @change="settings.setOneSetting('max_file_size_mo', params.max_file_size_mo)"
          @focus="selectContent">
        <label class="font-bold">
          {{ t('Mo') }}
        </label>
      </div>

      <!-- Change RP number pattern-->
      <div class="input-element">
        <label class="input-label">
          {{ t('RP Pattern') }}
        </label>
        <FieldSettings class="input-field"
          fkey="rp_pattern"
          :key="params.rp_pattern"
          :field="params.rp_pattern"
          @change="settings.setOneSetting"/>
      </div>
      <div>
        <label class="block italic text-sm text-left mb-1 pl-4">
          <span class="font-bold">%yyyy</span> - {{ t( 'RP Number pattern year 4 digits' ) }}<br>
          <span class="font-bold">%yy</span> - {{ t( 'RP Number pattern year 2 digits' ) }}<br>
          <span class="font-bold">%mm</span> - {{ t( 'RP Number pattern month 2 digits' ) }}<br>
          <span class="font-bold">%m</span> - {{ t( 'RP Number pattern month 1 or 2 digits' ) }}<br>
          <span class="font-bold">%dd</span> - {{ t( 'RP Number pattern day 2 digits' ) }}<br>
          <span class="font-bold">%d</span> - {{ t( 'RP Number pattern day 1 or 2 digits' ) }}<br>
          <span class="font-bold">%#</span> - {{ t( 'RP Number pattern x digits' ) }}<br>
          <span class="font-bold">%site</span> - {{ t( 'RP Number pattern site' ) }}<br>
          <span class="font-bold">%service</span> - {{ t( 'RP Number pattern service' ) }}<br>
          <span class="font-bold">{{ t( 'Example' ) }}</span> : {{ t( 'RP Number pattern example' ) }}
          <br>RP-%yyyy-%mm-%dd-#### &rarr; RP-2023-07-05-0012.
        </label>
      </div>

      <!-- Change app hour format -->
      <div class="input-element">
        <label class="input-label">
          {{ t('Time mode') }}
        </label>
        <div class="input-field">
          <label for="toggle" class="font-bold">{{ t( '12h' ) }}</label>
          <Toggle id="toggle" :value="params.time_24h" @toggle="toggleTime24h()"/>
          <label for="toggle" class="font-bold">{{ t( '24h' ) }}</label>
        </div>
      </div>

      <!-- Change 7M list -->
      <fieldset class="fieldset">
        <legend class="custom-legend fieldset-legend">
          {{ t('Ishikawa 7M Label') }}
          <div class="has-tooltip">
            <IconLoadingButton ref="reset7mBtn"
              icon="fa-solid fa-rotate-right"
              @click="resetSetting( settings.RESET_KEYS.ISHIKAWA_7M )"/>
            <span class='tooltip'>{{ t( 'Reset tooltip' )}}</span>
          </div>
        </legend>
        <div class="grid grid-cols-3 gap-4">
          <FieldSettings v-for="( ishikawa_7m, idx ) in params.ishikawa_7m"
            :key="ishikawa_7m"
            :fkey="idx"
            :field="ishikawa_7m"
            @change="changeIshikawa7m"/>
        </div>
      </fieldset>

      <!-- Change global company parameters -->
      <fieldset class="fieldset">
        <legend class="custom-legend fieldset-legend">{{ t('Company') }}</legend>
        <div class="input-element">
          <label class="input-label">
            {{ t('Name') }}
          </label>
          <FieldSettings class="input-field"
            fkey="company_name"
            :key="params.company_name"
            :field="params.company_name"
            @change="settings.setOneSetting"/>
        </div>

        <div class="input-element">
          <label class="input-label">
            {{ t('Address') }}
          </label>
          <FieldSettings class="input-field"
            fkey="company_address"
            :key="params.company_address"
            :field="params.company_address"
            @change="settings.setOneSetting"/>
        </div>

        <div class="input-element">
          <label class="input-label">
            {{ t('Email') }}
          </label>
          <FieldSettings class="input-field"
            fkey="company_email"
            :key="params.company_email"
            :field="params.company_email"
            @change="settings.setOneSetting"/>
        </div>

        <div class="input-element">
          <label class="input-label">
            {{ t('Phone') }}
          </label>
          <FieldSettings class="input-field"
            fkey="company_phone"
            :key="params.company_phone"
            :field="params.company_phone"
            @change="settings.setOneSetting"/>
        </div>

        <div class="input-element">
          <label class="input-label">
            {{ t('Company logo') }}
          </label>
          <div v-if="params.logo_path != null && params.logo_path !== ''"
            class="input-field">
            <img :src="utils.get_api_base_url() + '/' +  params.logo_path">
            <button class="delete-button"
              @click.prevent.stop="clickDeleteLogo">
              <font-awesome-icon icon="fa-solid fa-xmark" size="xl" class="px-2 py-1"/>
            </button>
          </div>
          <label v-else class="input-field">
            {{ t( 'No logo' ) }}
          </label>
        </div>

        <div class="input-element">
          <label class="has-tooltip input-label">
            {{ t('Select a logo') }}
            <font-awesome-icon icon="fa-solid fa-circle-info" class="mb-2 -ml-1" style="color: #2563eb;" size="sm"/>
            <span class='tooltip'>{{ t( 'Valid extensions' )}}</span>
          </label>
          <div class="input-field">
            <label class="custom-file-upload">
              <input type="file"
                id="company-logo-setting"
                name="company-logo-setting"
                accept="image/png, image/jpeg"
                @change="setNewLogo"/>
              <font-awesome-icon icon="fa-solid fa-upload"/>
              {{ t( 'Choose file' ) + "..." }}
            </label>
            <Spinner v-if="uploading" size="w-6"/>
          </div>
        </div>
      </fieldset>

      <!-- Non utilisé pour le moment -->
      <!-- <div class="md:flex md:items-center">
        <div class="md:w-1/2">
          <label class="block font-bold md:text-right mb-1 md:mb-0 pr-4">
            {{ t('Timezone') }}
          </label>
        </div>
        <div class="md:w-1/2">
          <div class="flex items-center">
            <div class="md:w-1/2">
              <select class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 secondary-text leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="timezone" v-model="params.timezone" @change="settings.setOneSetting('timezone', params.timezone)">
                <option v-for="timezone in moment.tz.names()" :key="timezone" :value="timezone">
                  {{ timezone }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="flex flex-col min-w-[50rem] space-y-4">
      <!-- Change capitalising actions templates list -->
      <fieldset class="fieldset">
        <legend class="custom-legend fieldset-legend">
          {{ t('Capitalising actions list') }}
          <div class="has-tooltip">
            <IconLoadingButton ref="resetCapitalisationBtn" icon="fa-solid fa-rotate-right"
              @click="resetSetting( settings.RESET_KEYS.CAPITALISATION_TEMPLATE )"/>
            <span class='tooltip'>{{ t( 'Reset tooltip' )}}</span>
          </div>
        </legend>
        <div class="w-fit">
          <LoadingButton ref="actionTemplateBtn"
            type="submit"
            :label="t( 'Add a template' )"
            @click="newActionTemplate"/>
        </div>

        <!-- Action templates scroll list -->
        <div v-if="params.capitalisation_template.length > 0"
          class="scroll-list-container">
          <!-- Action template item -->
          <div v-for="template in utils.sortListByRegisterDateAscending( params.capitalisation_template )"
            :key="template"
            class="flex items-center p-3 space-x-6 w-full border border-$c-divider border-gray-300">
            <div class="w-full">
              <Field :fkey="template.id"
                :field="template.title"
                :readonly="false"
                @change="changeTemplate"
                :maxlength="MAX_INPUT_LENGTH.CAPITALIZATION_TEMPLATE_TITLE"/>
            </div>
            <button @click="clickDeleteCapitalisation( template )"
              class="delete-button">
              <font-awesome-icon icon="fa-solid fa-xmark" size="xl"/>
            </button>
          </div>
        </div>
      </fieldset>

      <!-- Change Is/Is not default items list -->
      <fieldset class="fieldset">
        <legend class="custom-legend fieldset-legend">
          <div class="has-tooltip-4">
            {{ t( 'Is/Is not tool default list' ) }}
            <font-awesome-icon icon="fa-solid fa-circle-info" style="color: #2563eb;"/>
            <span class='tooltip'>{{ t( 'Is/Is not tool default list tooltip' ) }}</span>
          </div>
          <div class="has-tooltip">
            <IconLoadingButton ref="resetIsIsNotBtn"
              icon="fa-solid fa-rotate-right"
              @click="resetSetting( settings.RESET_KEYS.IS_IS_NOT_TEMPLATE )"/>
            <span class='tooltip'>{{ t( 'Reset tooltip' )}}</span>
          </div>
        </legend>
        <div class="w-fit">
          <LoadingButton ref="isIsNotactionTemplateBtn"
            type="submit"
            :label="t( 'Add a section' )" @click="newIsIsNotTemplate"/>
        </div>
        <!-- Is/Is not default items list -->
        <div v-if="params.is_is_not_template.length > 0"
          class="scroll-list-container">
          <!-- Is/Is not default item -->
          <div v-for="template in utils.sortListByRegisterDateAscending( params.is_is_not_template )"
            :key="template"
            class="flex items-center justify-between p-3 space-x-6 w-full hover:bg-fourth-hover border border-$c-divider border-gray-300 cursor-pointer"
            @click="changeIsIsNot( template )">
            <p>{{ template.label }}</p>
            <button @click.prevent.stop="clickDeleteIsIsNot( template )"
              class="delete-button">
              <font-awesome-icon icon="fa-solid fa-xmark" size="xl"/>
            </button>
          </div>
        </div>
      </fieldset>

      <!-- Priority matrix criteria list -->
      <fieldset class="fieldset">
        <legend class="custom-legend fieldset-legend">
          <div class="has-tooltip-4">
            {{ t( 'Priority matrix criteria' ) }}
            <font-awesome-icon icon="fa-solid fa-circle-info" style="color: #2563eb;"/>
            <span class='tooltip'>{{ t( 'Priority matrix criteria tooltip' ) }}</span>
          </div>
          <div class="has-tooltip">
            <IconLoadingButton ref="resetMatrixCriteriaBtn"
              icon="fa-solid fa-rotate-right"
              @click="resetSetting( settings.RESET_KEYS.PRIORITY_MATRIX )"/>
            <span class='tooltip'>{{ t( 'Reset tooltip' )}}</span>
          </div>
        </legend>
        <div class="w-fit">
          <LoadingButton ref="addMatrixCriterionBtn"
            type="submit"
            :label="t( 'Add a criterion' )"
            @click="newMatrixCriterion"/>
        </div>
        <!-- Priority matrix criteria items list -->
        <div v-if="params.priority_matrix_template.length > 0"
          class="scroll-list-container">
          <!-- Criteria item -->
          <div v-for="template in utils.sortListByRegisterDateAscending( params.priority_matrix_template )"
            :key="template"
            class="flex items-center justify-between p-3 space-x-6 w-full hover:bg-fourth-hover border border-$c-divider border-gray-300 cursor-pointer"
            @click="changeMatrixTemplate( template )">
            <p>{{ template.label }}</p>
            <button @click.prevent.stop="clickDeleteMatrixTemplate( template )"
              class="delete-button">
              <font-awesome-icon icon="fa-solid fa-xmark" size="xl"/>
            </button>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useModal, useModalSlot } from 'vue-final-modal';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import settings from '@/features/settings';
  import Toggle from '@/components/object/ToggleBasic.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import FieldSettings from '@/components/object/FieldSettings.vue';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import NewCapitalisationTemplateForm from '@/components/rp/capitalising_actions/NewCapitalisationTemplateForm.vue';
  import NewIsIsNotTemplateForm from '@/components/tools/is_is_not/NewIsIsNotTemplateForm.vue';
  import ChangeIsIsNotTemplateForm from '@/components/tools/is_is_not/ChangeIsIsNotTemplateForm.vue';
  import ModalConfirm from '@/components/modals/ModalConfirm.vue';
  import Spinner from '@/components/object/SpinnerBasic.vue';
  import IconLoadingButton from '@/components/buttons/IconLoadingButton.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';
  import Modal from '@/components/modals/ModalBasic.vue';
  import NewMatrixCriterionForm from '@/components/tools/priority_matrix/NewMatrixCriterionForm.vue';
  import ChangeMatrixTemplateForm from '@/components/tools/priority_matrix/ChangeMatrixTemplateForm.vue';

  const { t } = useI18n();
  const params = ref( {
    max_file_size_mo: 0,
    rp_pattern: '##',
    time_24h: false,
    color_sticky_note: '',
    company_name: '',
    company_address: '',
    company_email: '',
    company_phone: '',
    capitalisation_template: [],
    ishikawa_7m: [],
    is_is_not_template: [],
    priority_matrix_template: []
  } );

  const actionTemplateBtn = ref( null );

  const isIsNotactionTemplateBtn = ref( null );

  const addMatrixCriterionBtn = ref( null );

  const uploading = ref( false );

  const reset7mBtn = ref( null );

  const resetCapitalisationBtn = ref( null );

  const resetIsIsNotBtn = ref( null );

  const resetMatrixCriteriaBtn = ref( null );

  settings.getSettings().then( ( res ) => {
    params.value.max_file_size_mo = res.max_file_size_mo;
    params.value.rp_pattern = res.rp_pattern;
    params.value.time_24h = res.time_24h === true || res.time_24h === 'true';
    params.value.color_sticky_note = res.color_sticky_note;
    params.value.company_name = res.company_name;
    params.value.company_address = res.company_address;
    params.value.company_email = res.company_email;
    params.value.company_phone = res.company_phone;
    params.value.capitalisation_template = res.capitalisation_template;
    params.value.ishikawa_7m = res.ishikawa_7m;
    params.value.is_is_not_template = res.is_is_not_template;
    params.value.logo_path = res.logo_path;
    params.value.priority_matrix_template = res.priority_matrix_template;
  } );

  let confirmModal = useModal( {
    component: ModalConfirm,
    attrs: {
      onClosed() {
        stopLoadingBtn();
      },
      onCancel() {
        confirmModal.close();
      }
    }
  } );

  let modal = useModal( {
    component: Modal,
    attrs: {
      onClosed() {
        stopLoadingBtn();
      }
    }
  } );

  const stopLoadingBtn = () => {
    actionTemplateBtn.value ? actionTemplateBtn.value.stopLoading() : undefined;
    isIsNotactionTemplateBtn.value ? isIsNotactionTemplateBtn.value.stopLoading() : undefined;
    addMatrixCriterionBtn.value ? addMatrixCriterionBtn.value.stopLoading() : undefined;
    reset7mBtn.value ? reset7mBtn.value.stopLoading() : undefined;
    resetCapitalisationBtn.value ? resetCapitalisationBtn.value.stopLoading() : undefined;
    resetIsIsNotBtn.value ? resetIsIsNotBtn.value.stopLoading() : undefined;
    resetMatrixCriteriaBtn.value ? resetMatrixCriteriaBtn.value.stopLoading() : undefined;
  };

  const selectContent = ( event ) => {
    if ( event != null && event.target != null ) {
      event.target.select();
    }
  };

  const toggleTime24h = async () => {
    await settings.setOneSetting( 'time_24h', !params.value.time_24h );
    let newValue = await settings.getOneSetting( 'time_24h' );
    params.value.time_24h = ( newValue === true || newValue === 'true' );
  };

  const changeIshikawa7m = async ( idx, val ) => {
    let newValue = val;
    if ( idx != null && params.value.ishikawa_7m[ idx ] != null && newValue != null && newValue != '' ) {
      params.value.ishikawa_7m[ idx ] = newValue;
      let body = {
        locale: store.getters.getLocale,
        array: params.value.ishikawa_7m
      };
      await settings.setOneSetting( 'ishikawa_7m', body );
      params.value.ishikawa_7m = await settings.getOneSetting( 'ishikawa_7m' ) ?? [];
    }
  };

  const changeTemplate = async ( templateId, newTitle ) => {
    let template = params.value.capitalisation_template.find( ( template ) => {
      return template.id != null && template.id === templateId;
    } );
    if ( template != null && template.title != newTitle ) {
      let body = {
        locale: store.getters.getLocale,
        array: [ { id: templateId, title: newTitle, register_date: template.register_date } ]
      };
      await settings.setOneSetting( 'capitalisation_template', body );
      params.value.capitalisation_template = await settings.getOneSetting( 'capitalisation_template' ) ?? [];
    }
  };

  const clickDeleteCapitalisation = ( template ) => {
    confirmModal.patchOptions( {
      attrs: {
        header: t( 'Capitalisation template deletion confirmation HEADER' ),
        body: t( 'Capitalisation template deletion confirmation BODY' ),

        async onConfirm() {
          await deleteCapitalisation( template );
          confirmModal.close();
        }
      } } );
    confirmModal.open();
  };

  const deleteCapitalisation = async ( template ) => {
    if ( template != null && template.title != null && template.id != null ) {
      let body = {
        locale: 'fr',
        array: [ { id: template.id, title: template.title, delete: true } ]
      };
      await settings.setOneSetting( 'capitalisation_template', body );
      params.value.capitalisation_template = await settings.getOneSetting( 'capitalisation_template' ) ?? [];
    }
  };

  const clickDeleteIsIsNot = ( template ) => {
    confirmModal.patchOptions( {
      attrs: {
        header: t( 'Is/Is not criterion deletion confirmation HEADER' ),
        body: t( 'Is/Is not criterion deletion confirmation BODY' ),

        async onConfirm() {
          await deleteIsIsNotTemplate( template );
          confirmModal.close();
        }
      } } );
    confirmModal.open();
  };

  const deleteIsIsNotTemplate = async ( template ) => {
    if ( template != null && template.label != null && template.id != null ) {
      let body = {
        locale: 'fr',
        array: [ { id: template.id, delete: true } ]
      };
      await settings.setOneSetting( 'is_is_not_template', body );
      params.value.is_is_not_template = await settings.getOneSetting( 'is_is_not_template' ) ?? [];
    }
  };

  const newActionTemplate = () => {
    modal.patchOptions( {
      attrs: { header: t( 'Add a template' ) },
      slots: {
        content: useModalSlot( {
          component: NewCapitalisationTemplateForm,
          attrs: {
            onCancel() {
              modal.close();
            },
            async onAddTemplate( templateTitle ) {
              if ( templateTitle != null && templateTitle != '' ) {
                let body = {
                  locale: store.getters.getLocale,
                  array: [ { id: '', title: templateTitle } ]
                };
                await settings.setOneSetting( 'capitalisation_template', body );
                params.value.capitalisation_template = await settings.getOneSetting( 'capitalisation_template' ) ?? [];
              }
              modal.close();
            }
          }
        } )
      }
    } );
    modal.open();
  };

  const newIsIsNotTemplate = () => {
    modal.patchOptions( {
      attrs: { header: t( 'Add a section' ) },
      slots: {
        content: useModalSlot( {
          component: NewIsIsNotTemplateForm,
          attrs: {
            onCancel() {
              modal.close();
            },
            async onAddTemplate( template ) {
              if ( template != null && template.label != null && template.label != '' ) {
                let body = {
                  locale: store.getters.getLocale,
                  array: [ {
                    id: '',
                    label: template.label,
                    is_desc: template.is_desc != null && template.is_desc != '' ? template.is_desc : undefined,
                    is_not_desc: template.is_not_desc != null && template.is_not_desc != '' ? template.is_not_desc : undefined,
                    particularities_desc: template.particularities_desc != null && template.particularities_desc != '' ? template.particularities_desc : undefined,
                    changes_desc: template.changes_desc != null && template.changes_desc != '' ? template.changes_desc : undefined
                  } ]
                };
                await settings.setOneSetting( 'is_is_not_template', body );
                params.value.is_is_not_template = await settings.getOneSetting( 'is_is_not_template' ) ?? [];
              }
              modal.close();
            }
          }
        } )
      }
    } );
    modal.open();
  };

  const changeIsIsNot = ( isIsNot ) => {
    if ( isIsNot != null && isIsNot.id != null ) {
      modal.patchOptions( {
        attrs: { header: t( 'Modify a section' ) },
        slots: {
          content: useModalSlot( {
            component: ChangeIsIsNotTemplateForm,
            attrs: {
              is_is_not_template: isIsNot,
              onCancel() {
                modal.close();
              },
              async onChangeTemplate( template ) {
                if ( template != null && template.label != null && template.label != '' ) {
                  let body = {
                    locale: store.getters.getLocale,
                    array: [ {
                      id: template.id,
                      label: template.label != null && template.label != '' ? template.label : undefined,
                      is_desc: template.is_desc != null ? template.is_desc : undefined,
                      is_not_desc: template.is_not_desc != null ? template.is_not_desc : undefined,
                      particularities_desc: template.particularities_desc != null ? template.particularities_desc : undefined,
                      changes_desc: template.changes_desc != null ? template.changes_desc : undefined,
                      register_date: template.register_date
                    } ]
                  };
                  await settings.setOneSetting( 'is_is_not_template', body );
                  params.value.is_is_not_template = await settings.getOneSetting( 'is_is_not_template' ) ?? [];
                }
                modal.close();
              }
            }
          } )
        }
      } );
      modal.open();
    }
  };

  const setNewLogo = async ( event ) => {
    uploading.value = true;
    if ( event.target.files != null
      && event.target.files[ 0 ] != null ) {
      let fileToUpload = event.target.files[ 0 ];
      let maxFileSize = await settings.getOneSetting( 'max_file_size_mo' );
      if ( fileToUpload.size != null && fileToUpload.size <= maxFileSize * ( 1024 * 1024 ) ) { // Convert all sizes in Bytes/Octets
        await settings.setCompanyLogo( event.target.files[ 0 ] );
        let logo_path =  await settings.getOneSetting( 'logo_path' );
        params.value.logo_path = ( logo_path != null && logo_path !== '' ) ? logo_path + '?timestamp=' + new Date().getTime() : ''; // Timestamp is a fake parameter used in order to update the logo displayed
      } else {
        utils.showAlert( t( 'Warning file to big', { fileName: fileToUpload.name ?? '' } ) );
      }
    }
    uploading.value = false;
  };

  const clickDeleteLogo = () => {
    confirmModal.patchOptions( {
      attrs: {
        header: t( 'Logo deletion HEADER' ),
        body: t( 'Logo deletion BODY' ),

        async onConfirm() {
          await settings.deleteLogo();
          params.value.logo_path = await settings.getOneSetting( 'logo_path' ) ?? '';
          confirmModal.close();
        }
      } } );
    confirmModal.open();
  };

  const resetSetting = ( key ) => {
    confirmModal.patchOptions( {
      attrs: {
        header: t( key + ' HEADER' ),
        body: t( key + ' BODY' ),

        async onConfirm() {
          if ( key != null && key != '' ) {
            await settings.resetSetting( key );
            params.value[ key ] = await settings.getOneSetting( key ) ?? [];
          }
          confirmModal.close();
        }
      }
    } );
    confirmModal.open();
  };

  const newMatrixCriterion = () => {
    modal.patchOptions( {
      attrs: { header: t( 'Add a criterion' ) },
      slots: {
        content: {
          component: NewMatrixCriterionForm,
          attrs: {
            onClose() {
              modal.close();
            },
            async onAdd( template ) {
              if ( template != null && template.label != null && template.label != '' ) {
                let body = {
                  locale: store.getters.getLocale,
                  array: [ {
                    id: '',
                    label: template.label,
                    label_tooltip: template.label_tooltip != null && template.label_tooltip != '' ? template.label_tooltip : undefined
                  } ]
                };
                await settings.setOneSetting( 'priority_matrix_template', body );
                params.value.priority_matrix_template = await settings.getOneSetting( 'priority_matrix_template' ) ?? [];
              }
              modal.close();
            }
          }
        }
      }
    } );
    modal.open();
  };

  const changeMatrixTemplate = ( template ) => {
    if ( template != null && template.id != null ) {
      modal.patchOptions( {
        attrs: { header: t( 'Modify a section' ) },
        slots: {
          content: {
            component: ChangeMatrixTemplateForm,
            attrs: {
              template: template,
              onClose() {
                modal.close();
              },
              async onChangeTemplate( template ) {
                if ( template != null && template.label != null && template.label != '' ) {
                  let body = {
                    locale: store.getters.getLocale,
                    array: [ {
                      id: template.id,
                      label: template.label != null && template.label.trim() != '' ? template.label : undefined,
                      label_tooltip: template.label_tooltip != null ? template.label_tooltip : undefined,
                      register_date: template.register_date != null ? template.register_date : undefined,
                    } ]
                  };
                  await settings.setOneSetting( 'priority_matrix_template', body );
                  params.value.priority_matrix_template = await settings.getOneSetting( 'priority_matrix_template' ) ?? [];
                }
                modal.close();
              }
            }
          }
        }
      } );
      modal.open();
    }
  };

  const clickDeleteMatrixTemplate = ( template ) => {
    confirmModal.patchOptions( {
      attrs: {
        header: t( 'Is/Is not criterion deletion confirmation HEADER' ),
        body: t( 'Is/Is not criterion deletion confirmation BODY' ),

        async onConfirm() {
          await deleteMatrixTemplate( template );
          confirmModal.close();
        }
      }
    } );
    confirmModal.open();
  };

  const deleteMatrixTemplate = async ( template ) => {
    if ( template != null && template.label != null && template.id != null ) {
      let body = {
        locale: 'fr',
        array: [ { id: template.id, delete: true } ]
      };
      await settings.setOneSetting( 'priority_matrix_template', body );
      params.value.priority_matrix_template = await settings.getOneSetting( 'priority_matrix_template' ) ?? [];
    }
  };
</script>

<style scoped lang="scss">
  input[type="file"] {
      display: none;
  }

  .custom-file-upload {
    @apply inline-flex justify-center items-center w-fit h-8 gap-x-2;
    @apply bg-primary hover:bg-primary-hover hover:cursor-pointer;
    @apply text-primary-text;
    @apply font-bold px-4 rounded;
    @apply flex items-center;
  }

  .settings-label {
    @apply bg-gray-200;
    @apply appearance-none;
    @apply border-2;
    @apply border-gray-200;
    @apply rounded;
    @apply w-full;
    @apply py-2;
    @apply px-4;
    @apply text-gray-700;
    @apply leading-tight;
    @apply focus:outline-none;
    @apply focus:bg-white;
    @apply focus:border-purple-500;
  }

  .fieldset {
    @apply border border-gray-400;
    @apply rounded;
    @apply space-y-4 p-4 pt-0 w-full h-fit;
  }

  .fieldset-legend {
    @apply font-bold flex items-center gap-x-4;
  }

  .input-label {
    @apply md:w-1/2 font-bold md:text-right;
  }

  .input-element {
    @apply md:flex md:items-center gap-x-4;
  }

  .input-field {
    @apply flex items-center gap-x-1 md:w-[37.5%];
  }

  .delete-button {
    @apply hover:bg-secondary-hover rounded-full px-2 py-1 ;
  }

  .scroll-list-container {
    @apply max-h-[300px] border-2 border-gray-400 rounded overflow-auto;
  }
</style>