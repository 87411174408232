<template>
  <div class="fixed z-40 top-0 mt-32 w-full">
    <div class="text-center">
      <div class="grid grid-cols-2 mx-4 mt-4 bg-white"
        :style="`grid-template-columns: 10rem repeat(${settings.n_days}, minmax(0, 1fr));`">
        <div class="p-2 h-10"></div>
        <div v-for="day in settings.n_days" :key="day">
          <div class="p-2 h-10">
            {{ moment(settings.start_date).add(day-1, 'day').format('L') }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="grid mx-4 mt-48 mb-24"
    :style="`grid-template-columns: 10rem repeat(${settings.n_days}, minmax(0, 1fr));`">
    <div class="border border-black p-2 flex flex-wrap place-content-center"
      v-for="(obj, idx) in objects" :key="obj" :droppable="!isUserCell(obj)"
      @drop="drop" @dragover="allowDrop" :cell="getCellID(idx, obj)">
      <div v-for="element in obj.elements" :key="element" :droppable="element.type !== 'user'"
        class="w-48 place-content-center" :cell="getCellID(idx, obj)">
        <BoardUser v-if="element.type == 'user'"
          :user="element.item" draggable="false" droppable="false"/>
        <BoardAction v-if="element.type == 'action'" :action="element.item"
          draggable="true" @drop.stop="drop"
          :cell="getCellID(idx, obj)"
          @dragstart="dragStart" @click="clickToAction(element.item);"/>
      </div>
    </div>
  </div>
</template>


<script setup>
  import { onMounted, ref, watch } from 'vue';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import moment from 'moment';
  import utils from '@/features/utils';
  import BoardUser from '@/components/board/BoardUser.vue';
  import BoardAction from '@/components/board/BoardAction.vue';
  import API from '@/constants/api.constants';
  import Modal from '@/components/modals/ModalBasic.vue';
  import { useModal } from 'vue-final-modal';
  import ActionDetails from '@/components/rp/actions/ActionDetails.vue';
  import { useI18n } from 'vue-i18n';

  const props = defineProps( {
    settings: { Object },
    themes: { Array, required: true },
    items: { Array, required: true }
  } );
  const emit = defineEmits( [ 'redraw-calendar' ] );

  const { t } = useI18n();

  const objects = ref( [] );
  var lastCellUserID = null;
  var dragStartValue = null;
  var dragStartDate = null;
  var dragStartPilote = null;

  // eslint-disable-next-line vue/no-setup-props-destructure
  watch( props.settings, () => {
    init();
  } );

  const forceItemsRerender = () => {
    emit( 'redraw-calendar' );
  };

  const init = () => {
    objects.value = [];

    props.items.forEach( ( item ) => {
      objects.value.push( { elements: [ { type: 'user', item: item.user } ] } );
      for ( let day = 0; day < props.settings.n_days; day++ ) {
        let dates = moment( props.settings.start_date ).add( day, 'day' );
        let li = [];
        item.actions.forEach( ( action ) => {
          if ( ( !action.archive_date || action.archive_date === '' )
            && action.delivery_date && action.delivery_date !== '' ) {
            action.theme = utils.get_idx_theme_color( props.themes, action.number );
            if ( moment( dates ).isSame( action.delivery_date ) ) {
              action.late = 0;
              li.push( { type: 'action', item: action } );
            } else if ( action.complete_date && action.complete_date !== '' && moment( action.delivery_date ).isBefore( moment() ) && moment( dates ).isSame( moment().format( 'YYYY-MM-DD' ) ) ) {
              let lateDays = moment( action.complete_date ).diff( action.delivery_date, 'days' );
              action.late = lateDays > 0 ? lateDays : 0;
              li.push( { type: 'action', item: action } );
            } else if ( moment( action.delivery_date ).isBefore( moment() ) && moment( dates ).isSame( moment().format( 'YYYY-MM-DD' ) ) ) {
              action.late = moment( dates ).diff( action.delivery_date, 'days' );
              li.push( { type: 'action', item: action } );
            }
          }
        } );
        objects.value.push( { elements: li } );
      }
    } );
  };

  onMounted( init );

  const isUserCell = ( obj ) => {
    if ( obj.elements != null && Array.isArray( obj.elements )
      && obj.elements.length > 0 && obj.elements[ 0 ] != null
      && obj.elements[ 0 ].type != null && obj.elements[ 0 ].type == 'user' ) {
      return true;
    }
    return false;
  };
  const dragStart = ( event ) => {
    dragStartValue = event.target.getAttribute( 'id_action_user' );
    dragStartDate = event.target.getAttribute( 'deliveryDate' );
    dragStartPilote = event.target.getAttribute( 'pilote' );
    event.dataTransfer.setData( 'text', event.target.getAttribute( 'cell' ) );
  };

  const drop = ( event ) => {
    let pilote = JSON.parse( event.target.getAttribute( 'cell' ) ).user;
    let action_date = JSON.parse( event.target.getAttribute( 'cell' ) ).date;
    if ( ( dragStartDate != null && action_date != null && dragStartDate !== action_date )
      || ( dragStartPilote != null && pilote != null && dragStartPilote !== pilote ) ) {
      let url = API.get_api( API.API_NAMES.ACTIONS_USERS, dragStartValue );
      AXIOS.put( url, { id_users: pilote, delivery_date: action_date }, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          if ( response.status == '201' ) {
            forceItemsRerender();
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          }
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const allowDrop = ( event ) => {
    if ( event.target.getAttribute( 'droppable' ) == 'true' && dragStartValue && dragStartDate ) {
      event.preventDefault();
    }
  };

  const clickToAction = ( action ) => {
    let modal = useModal( {
      component: Modal,
      attrs: { header: t( 'Event details' ) },
      slots: {
        content: {
          component: ActionDetails,
          attrs: {
            action: action.full_action,
            id_user: action.id_action_user,
            id_rp: action.id_rp,

            onClose() {
              modal.close();
            },
            onChangeItem() {
              forceItemsRerender();
            }
          }
        }
      }
    } );
    modal.open();
  };

  const getCellID = ( index, obj ) => {
    let idx = index % ( props.settings.n_days + 1 );
    if ( idx === 0 ) {
      lastCellUserID = obj.elements[ 0 ].item.id;
      return lastCellUserID;
    } else {
      let date = moment( props.settings.start_date ).add( idx - 1, 'day' ).format( 'YYYY-MM-DD' );
      return '{"user": "' + lastCellUserID + '", "date": "' + date + '"}';
    }
  };
</script>