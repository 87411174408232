<template>
  <div class="flex-col space-y-8">
    <!-- Modal Body -->
    <div class="space-y-2 text-xl" :key="componentKey">
      <div class="flex h-fit w-full gap-x-10">
        <div class="flex h-fit gap-x-3 max-w-[24rem]">
          <div class="font-bold">{{ t( 'Category' ) }}</div>
          <InputSelect :items="categories" :selected="curCause.category" :readonly="readonly" @change-value="changeCategory"/>
        </div>
        <div class="flex h-fit gap-x-3 grow">
          <div class="font-bold w-fit">{{ t( 'Ranking' ) }}</div>
          <Number :min="1" :max="500" :field="curCause.position" fkey="position" :readonly="readonly" @change="changeField" oneline/>
        </div>
      </div>
      <FieldArea class="flex-auto text-xl"
        :placeholder="t( 'Description' ) + '...'" :field="curCause.description"
        fkey="description" :readonly="readonly" @change="changeField"
        :maxlength="MAX_INPUT_LENGTH.CAUSE_DESCRIPTION"/>
      <div v-if="curCause.path != null && curCause.path !== ''" class="flex flex-row flex-wrap gap-1 w-52 mt-4">
        <img class="aspect-square w-40" :src="utils.causeImgPath( curCause.path )"/>
        <div class="relative" droppable="false">
          <div v-if="!readonly" class="absolute bottom-1 right-3" droppable="false">
            <button droppable="false"><font-awesome-icon icon="fas fa-trash-can" @click="remove_picture()" droppable="false"/></button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal footer-->
    <div v-if="!readonly" class="flex space-x-4 justify-end">
      <div>
        <LoadingButton type="submit" :label="t('Delete')" @click="remove" ref="deleteBtn" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, watchEffect } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import API from '@/constants/api.constants';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import InputSelect from '@/components/object/InputSelect.vue';
  import FieldArea from '@/components/object/FieldArea.vue';
  import Number from '@/components/object/NumberInput.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const props = defineProps( {
    categories: { Array, requried: true },
    cause: { Object, required: true },
    id_rp: { String, required: true },
    readonly: { Boolean, required: false, default: false }
  } );
  const emit = defineEmits( [ 'close', 'changeItem', 'removeItem' ] );

  const { t } = useI18n();

  const componentKey = ref( 0 );
  const deleteBtn = ref();
  const curCause = ref( );
  watchEffect( () => ( curCause.value = props.cause ) );

  const changeItem = () => {
    emit( 'changeItem', curCause.value.id );
  };

  const removeItem = () => {
    emit( 'removeItem', curCause.value.id  );
  };

  const clickClose = () => {
    emit( 'close' );
  };

  const changeField = ( fkey, val ) => {
    let url = API.get_api( API.API_NAMES.CAUSES, curCause.value.id );
    AXIOS.put( url, { [ fkey ]: val }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          changeItem();
          curCause.value[ fkey ] = val;
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  const changeCategory = ( val ) => {
    changeField( 'category', val );
  };

  const remove = () => {
    let url = API.get_api( API.API_NAMES.CAUSES, 'disable', curCause.value.id );
    AXIOS.delete( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          removeItem();
          clickClose();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  const remove_picture = () => {
    changeField( 'path', '' );
  };
</script>