<template>
  <div class="space-y-5">
    <div class="flex flex-col text-xl space-y-4">
      <Field :title="t( 'Section label' )"
        :placeholder="t( 'Label' )"
        :field="template.label"
        fkey="label"
        @change="changeField"
        :maxlength="MAX_INPUT_LENGTH.TOOL_PRIORITY_MATRIX_LABEL"/>
      <FieldArea :title="t( 'Criterion description' )"
        :placeholder="t( 'Criterion description' )"
        :field="template.label_tooltip"
        fkey="label_tooltip"
        class="text-lg"
        @change="changeField"
        :maxlength="MAX_INPUT_LENGTH.TOOL_PRIORITY_MATRIX_LABEL_TOOLTIP"/>
    </div>

    <div class="flex space-x-4 pt-4 justify-end">
      <div>
        <LoadingButton :label="t( 'Save' )" @click="changeTemplate" :disabled="!canAdd"/>
      </div>
      <div>
        <LoadingButton :label="t( 'Cancel' )" @click="close"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import FieldArea from '@/components/object/FieldArea.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const props = defineProps( {
    template: { Object, required: true }
  } );
  const emit = defineEmits( [ 'changeTemplate', 'close' ] );

  const template = ref( Object.assign( { }, props.template ) );
  const { t } = useI18n();

  const canAdd = computed( () => {
    return template.value.label != null && template.value.label.trim() !== '';
  } );

  const changeField = ( fkey, newValue ) => {
    if ( fkey && fkey != '' && newValue != null ) {
      template.value[ fkey ] = newValue;
    }
  };

  const changeTemplate = () => {
    if ( template.value != null && canAdd ) {
      emit( 'changeTemplate', template.value );
    } else {
      close();
    }
  };

  const close = () => {
    emit( 'close' );
  };
</script>