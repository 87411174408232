<template>
  <GlobalIndicatorStd :start_date="start_date" :change_date="eventDateChange">
    <template v-slot:header> {{ t( 'Duration RP Title Chart' ) }} </template>
    <template v-slot:graph>
      <div class="flex h-full w-full place-content-center">
        <VChart class="flex-none" :option="option" @click="eventClick" :autoresize="false" v-if="option"/>
        <Spinner v-else size="w-24"/>
      </div>
    </template>
  </GlobalIndicatorStd>
</template>

<script setup>
  import GlobalIndicatorStd from '@/components/indicators/global_indicators/GlobalIndicatorStd.vue';
  import DisplayArray from '@/components/object/DisplayArray.vue';
  import { ref, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { use } from 'echarts/core';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import moment from 'moment';
  import AXIOS from '@/features/axios.js';
  import API from '@/constants/api.constants';
  import { BarChart } from 'echarts/charts';
  import { SVGRenderer } from 'echarts/renderers';
  import { useModal } from 'vue-final-modal';
  import Spinner from '@/components/object/SpinnerBasic.vue';
  import Modal from '@/components/modals/ModalBasic.vue';

  import {
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent
  } from 'echarts/components';
  use( [
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    BarChart,
    SVGRenderer
  ] );

  const DEFAULT_DATE = moment().format( 'YYYY-MM-DD' );

  const props = defineProps( {
    readOnly: { Boolean, required: false, default: false }
  } );

  const emit = defineEmits( [ 'isBusy' ] );
  const { t } = useI18n();

  let option = ref( null );
  let rp_array = ref( [] );
  let start_date = ref( DEFAULT_DATE );

  watch( props, async () => {
    if ( option.value ) {
      option.value.series[ 0 ].silent = props.readOnly;
    }
  } );

  const getDataIndicator = () => {
    // Get Data for Duration RP Chart
    let url = API.get_api( API.API_NAMES.GLOBAL_INDICATORS_DURATION_RP, start_date.value );
    AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        var rp = [];
        var xAxisData = [];
        let max_yAxis = 0;

        response.data.forEach( ( element, index ) => {
          let number_day = 30;
          let first_day =  ( number_day * element.index + ( element.index === 0 ? 0 : 1 ) );
          let xLabel = '';
          if ( index < response.data.length - 1 ) {
            let last_day = ( number_day * ( element.index + 1 ) + 1 ) - 1;
            xLabel = t( 'from x to y days', [ first_day, last_day ] );
          } else {
            xLabel = t( 'more than or equal to x days', [ first_day ] );
          }
          if ( max_yAxis < element.number_rp ) {
            max_yAxis = element.number_rp;
          }
          xAxisData.push( xLabel );
          rp.push( element.number_rp );
          rp_array.value.push( element.rp );
        } );

        option.value = {
          color: [ '#45D52E' ],
          tooltip: {
            formatter: '<div class="flex gap-1"><span class="place-self-center rounded-full w-[10px] h-[10px] bg-primary"></span> {b0} <p class="font-bold ml-3">{c0}</p></div>'
          },
          grid: {
            top: '5%',
            bottom: '0%',
            left: '3%',
            right: '1%',
            containLabel: true
          },
          xAxis: {
            data: xAxisData,
            axisLabel: {
              interval: 0,
              rotate: 45,
              fontSize: 10,
            }
          },
          yAxis: {
            minInterval: 1,
            axisLabel: {
              interval: 0,
              fontSize: 10
            }
          },
          series: [
            {
              name: t( 'RP' ),
              type: 'bar',
              stack: 'one',
              data: rp
            }
          ]
        };

        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };
  getDataIndicator();

  function eventDateChange( params ) {
    option.value = null;
    rp_array.value = [];
    start_date.value = moment( params ).format( 'YYYY-MM-DD' );
    getDataIndicator();
  }

  function eventClick( params ) {
    if ( params ) {
      emit( 'isBusy' );
      let list = rp_array.value[ params.dataIndex ];
      let rp_list = [];
      let promises = [];

      for ( let item of list ) {
        let url = API.get_api( API.API_NAMES.RP_BY_ID, item.id_rp );
        promises.push( new Promise ( ( resolve ) => AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            let id = response.data.id;
            let rp_number = response.data.number;
            let rp_title = response.data.title;
            let rp_pilote = response.data.pilote.fullname;
            let rp_open_date = moment( response.data.open_date ).locale( store.getters.getLocale ).format( 'L' );
            let id_rp_redirection = item.id_rp;

            rp_list.push( { id, rp_number, rp_title, rp_pilote, rp_open_date, id_rp_redirection } );
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            utils.showAxiosError( response );
            resolve();
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
            resolve();
          } )
        ) );
      }

      Promise.all( promises )
        .then( () => {
          displayRpList( rp_list );
        } );
    }
  }

  const displayRpList = ( list ) => {
    if ( list != null && Array.isArray( list ) ) {
      let modal = useModal( {
        component: Modal,
        attrs: {
          header: t( 'Resolution' ),
          escToClose: true,
          clickToClose: true,
          lockScroll: true
        },
        slots: {
          content: {
            component: DisplayArray,
            attrs: {
              type: 'rp',
              array: list,
              onCancel() {
                modal.close();
              }
            }
          }
        }
      } );
      modal.open();
      emit( 'isBusy' );
    }
  };
</script>