<template>
  <div class="text-sm bg-white shadow shadow-shadowColor rounded group mb-4">
    <div class="focus-visible:outline-none rounded">
      <div @click="toggleExpander"
        class="flex flex-row content-center items-center space-x-3 px-4 py-2 text-lg rounded hover:bg-tertiary-hover transition duration-200">
        <font-awesome-icon icon="fa-solid fa-shield-halved" size="xl"/>
        <div class="font-bold">{{ t( 'Securing Actions' ) }}</div>
        <button v-if="!readonly" @click.stop="addAction" :disabled="isBusy"
          class="flex flex-col has-tooltip  text-xxl rounded-full align-center justify-center"
          :class="!isBusy ? 'hover:bg-fourth-hover text-primary' : 'text-fourth-hover'">
          <font-awesome-icon icon="fa-solid fa-plus" size="xl" class="px-1 py-0"/>
          <span class='tooltip mb-8'>{{ t( 'Add a securing action' ) }}</span>
        </button>
        <div class="has-tooltip flex">
          <span class='tooltip'>{{ t( 'Securing actions total' ) }}</span>
          <span class="items-center px-1.5 pb-0.5 font-bold rounded-full bg-gray-500 text-white">
            {{ numberOfSecuringActions }}
          </span>
        </div>
        <SortAndFilterOptions
          :sortOptions="sortOptions"
          :sortSelected="sortOptionSelected"
          :filterOptions="filterOptions"
          :filterSelected="filterOptionSelected"
          @changeSortOption="changeSortOption"
          @changeFilterOption="changeFilterOption"/>
        <div class="flex flex-row">
          <font-awesome-icon v-if="isToggled" icon="fa-solid fa-chevron-right" rotation="90" size="xl"/>
          <font-awesome-icon v-else icon="fa-solid fa-chevron-right" size="xl"/>
        </div>
      </div>
    </div>
    <Collapse :when="isToggled" class="collapse-class w-full">
      <div class="float-right flex flex-row gap-x-4 -mb-2 mt-1 mr-4">
        <div class="">
          <div class="p-1 flex flex-row font-bold text-blue-500 hover:bg-tertiary-hover">
            <div class="z-10">
              <div class="cursor-pointer" @click="openImpactStudies">
                {{ t( 'Impact Studies' ) }}
              </div>
            </div>
            <div v-if="number_no_answer > 0" class="has-tooltip-2">
              <font-awesome-icon icon="fa-solid fa-circle-exclamation"
                class="text-red-500 align-top -mt-[2px] ml-[2px]"/>
               <div class="tooltip">{{ numberNoAnswerTooltip }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap w-full p-4 gap-4">
        <RPSecuringActionItem v-for="action in sortAndFilterActionsList"
          :key="action"
          class="flex-auto"
          :users="users" :action="action"
          :impact_studies="impact_studies"
          :detectedDate="detectedDate"
          :readonly="readonly"
          @changeItem="changeItem"
          @removeItem="removeItem"/>
      </div>
    </Collapse>
  </div>
</template>

<script setup>
  import { ref, watchEffect, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useModal } from 'vue-final-modal';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import utils from '@/features/utils.js';
  import RPSecuringActionItem from '@/components/rp/securing_actions/RPSecuringActionItem.vue';
  import API from '@/constants/api.constants';
  import SortAndFilterOptions from '@/components/object/SortAndFilterOptions.vue';
  import SORT from '@/constants/sort.constants';
  import FILTER from '@/constants/filter.constants';
  import ToolImpactStudies from '@/components/tools/ToolImpactStudies.vue';
  import Modal from '@/components/modals/ModalBasic.vue';

  const props = defineProps( {
    id_rp: { String, required: true },
    readonly: { Boolean, required: false, default: false },
    detectedDate: { String, required: false, default: null },
    securingActions: { Array, required: true, default: [] },
    users: { Array, required: true, default: null },
    rp_impact_studies: { Array, required: true, default: null }
  } );
  const emit = defineEmits( [ 'changeList', 'changeItem', 'removeItem' ] );

  const { t } = useI18n();
  const isToggled = ref( store.getters.getExpanders.securingActions ?? true );

  const isBusy = ref( false );
  const impact_studies = ref( [] );
  const number_no_answer = computed( () => {
    return impact_studies.value.filter( ( impact_study ) =>
      !impact_study.answer ||
      impact_study.answer == ''
    ).length;
  } );

  const numberNoAnswerTooltip = computed( () => {
    return number_no_answer.value === 1
      ? t( '{0} impact study with no response', [ number_no_answer.value ] )
      : t( '{0} impact studies with no response', [ number_no_answer.value ] );
  } );

  watchEffect( () => {
    if ( props.rp_impact_studies ) {
      impact_studies.value = utils.sortListByRegisterDateAscending(
        props.rp_impact_studies.map(
          ( rp_impact_study ) => { return rp_impact_study.impact_study; }
        )
      );
    }
  } );

  const sortOptionSelected = ref( store.getters.getSecuringActionsOptions.sort ?? SORT.REGISTER_DATE_DESC );
  const sortOptions = ref( [
    SORT.REGISTER_DATE_DESC,
    SORT.REGISTER_DATE_ASC,
    SORT.LAST_ACTIVITY_DESC,
    SORT.LAST_ACTIVITY_ASC,
    SORT.TITLE_DESC,
    SORT.TITLE_ASC
  ].map( ( item ) => {
    return { val: item, text: t( item ) };
  } ) );
  const filterOptionSelected = ref( store.getters.getSecuringActionsOptions.filter ?? FILTER.ALL );
  const filterOptions = ref( [
    FILTER.ALL,
    FILTER.WITH_PILOT,
    FILTER.WITHOUT_PILOT,
    FILTER.WITH_SECURING_DATE,
    FILTER.WITHOUT_SECURING_DATE
  ].map( ( item ) => {
    return { val: item, text: t( item ) };
  } ) );
  const numberOfSecuringActions = computed( () => {
    let total = 0;
    if ( props.securingActions != null
      && Array.isArray( props.securingActions ) ) {
      total = props.securingActions.length;
    }
    return total;
  } );
  const sortAndFilterActionsList = computed( () => {
    return utils.sortActions( sortOptionSelected.value, filterSecuringActions( props.securingActions ) );
  } );

  const changeSortOption = ( newOption ) => {
    sortOptionSelected.value = newOption;
    store.dispatch( 'setSecuringActions', { sort: newOption } );
  };
  const changeFilterOption = ( newOption ) => {
    filterOptionSelected.value = newOption;
    store.dispatch( 'setSecuringActions', { filter: newOption } );
  };

  const toggleExpander = () => {
    store.dispatch( 'setExpanders', { securingActions: !isToggled.value } );
    isToggled.value = store.getters.getExpanders.securingActions;
  };
  defineExpose( { toggleExpander, isToggled } );

  const changeList = () => {
    emit( 'changeList' );
  };

  const changeItem = ( id ) => {
    emit( 'changeItem', id );
  };

  const removeItem = ( id ) => {
    emit( 'removeItem', id );
  };

  const addAction = () => {
    let url = API.get_api( API.API_NAMES.RP_SECURING_ACTION, props.id_rp );
    let body = {
      title: t( 'Title PLACEHOLDER' )
    };
    isBusy.value = true;
    AXIOS.post( url, body, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        utils.analyse_axios_request( response );
        if ( response.status == '200' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          changeList();
        }
        isBusy.value = false;
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        isBusy.value = false;
        utils.showCatch( err );
      } );
  };

  const openImpactStudies = async () => {
    let modal = useModal( {
      component: Modal,
      attrs: {
        header: t( 'Impact Studies' ),
        clickToClose: true
      },
      slots: {
        content: {
          component: ToolImpactStudies,
          attrs: {
            readonly: props.readonly,
            id_rp: props.id_rp,
            impact_studies: impact_studies.value ?? [],
            onUpdate( params ) {
              impact_studies.value.find( ( impact_study ) => impact_study.id === params[ 0 ] ).answer = params[ 1 ];
            },
            onAddActions( newItems ) {
              if ( newItems === true || newItems === 'true' ) {
                changeList();
              }
              modal.close();
            }
          }
        }
      }
    } );
    modal.open();
  };

  const filterSecuringActions = ( securingActionsList ) => {
    if ( securingActionsList != null && Array.isArray( securingActionsList ) ) {
      switch ( filterOptionSelected.value ) {
      case ( FILTER.ALL ):
        return securingActionsList;
      case ( FILTER.WITH_PILOT ):
        return securingActionsList.filter( ( action ) => {
          return ( action.action_users != null
            && Array.isArray( action.action_users )
            && action.action_users.length > 0 );
        } );
      case ( FILTER.WITHOUT_PILOT ):
        return securingActionsList.filter( ( action ) => {
          return  action.action_users == null ||
            ( action.action_users != null
              && Array.isArray( action.action_users )
              && action.action_users.length == 0 );
        } );
      case ( FILTER.WITH_SECURING_DATE ):
        return securingActionsList.filter( ( action ) => {
          return ( action.action_users != null
            && Array.isArray( action.action_users )
            && action.action_users.length > 0
            && action.action_users[ 0 ].delivery_date != null
            && action.action_users[ 0 ].delivery_date != '' );
        } );
      case ( FILTER.WITHOUT_SECURING_DATE ):
        return securingActionsList.filter( ( action ) => {
          return  action.action_users == null ||
            ( action.action_users != null
              && Array.isArray( action.action_users )
              && action.action_users.length == 0
            ) ||
            ( action.action_users != null
              && Array.isArray( action.action_users )
              && action.action_users.length > 0
              && ( action.action_users[ 0 ].delivery_date == null
                || action.action_users[ 0 ].delivery_date == '' )
            );
        } );
      default:
        return securingActionsList;
      }
    }
    return [];
  };
</script>