<template>
  <Modal :clickToClose="true" :header="header">
    <template #content>
      <div class="flex flex-col space-y-4">

        <h1 class="text-xl px-3" v-html="body"></h1>

        <div class="flex space-x-4 justify-end">
          <div>
            <LoadingButton :label="labelOpt1" @click="confirm"/>
          </div>
          <div>
            <LoadingButton :label="labelOpt2" @click="clickClose"/>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
  import { useI18n } from 'vue-i18n';
  import Modal from '@/components/modals/ModalBasic.vue';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';

  const props = defineProps( {
    header: { String, required: false },
    body: { String, required: false },
    labelOpt1: { String, required: false },
    labelOpt2: { String, required: false },
    escToClose: { Boolean, required: false, default: true },
    clickToClose: { Boolean, required: false, default: true },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false },
    contentTransition: { String, required: false }
  } );
  const emit = defineEmits( [ 'confirm', 'cancel' ] );

  const { t } = useI18n();

  var header = props.header || t( 'Confirmation' );
  var body = props.body || '';
  var labelOpt1 = props.labelOpt1 || t( 'Yes' );
  var labelOpt2 = props.labelOpt2 || t( 'No' );

  const confirm = () => {
    emit( 'confirm' );
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>