<template>
  <img src="@/../public/img/congrats.gif" class="z-0 w-full h-full">

  <p class="absolute inset-0 pt-[20%] text-center text-5xl font-bold z-0">
    {{ t( 'Congratulations' ) }}
  </p>

  <!-- Modal footer-->
  <div class="absolute z-10 bottom-0 right-0 flex space-x-4 justify-end p-2">
    <div>
      <LoadingButton type="submit" :label="t( 'Go to home page' )" @click="goHome"/>
    </div>
    <div>
      <LoadingButton type="submit" :label="t( 'Stay here' )" @click="clickClose"/>
    </div>
  </div>
</template>

<script setup>
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';

  const emit = defineEmits( [ 'goHome', 'cancel' ] );

  const { t } = useI18n();

  const goHome = () => {
    emit( 'goHome' );
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>