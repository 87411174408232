<template>
  <div class="bg-white shadow shadow-shadowColor rounded group p-4">
    <div class="flex w-auto justify-center">
      <div class="w-32">
        <LoadingButton ref="actionBtn" v-if="rp.state == RP_STATE.OPEN"
          type="submit"
          :label="t('Closing')"
          @click="modalConfirm.open()"/>
        <LoadingButton ref="actionBtn" v-if="rp.state == RP_STATE.CLOSE"
          type="submit"
          :label="t('Re-open')"
          @click="reopen"/>
      </div>
    </div>
    <div class="w-full mt-4">
      <RPMainDetails :rp="rp"
        :readonly="readonly"
        @changeItem="changeRP"
        @changeUsers="changeUsers"/>
    </div>
    <div class="flex w-auto mt-4 justify-left">
      <div>
        <LoadingButton ref="downloadReportBtn"
          type="submit"
          :label="t( 'Download report' )"
          @click="clickDownloadReport"/>
      </div>
      <div class="ml-2">
        <IconLoadingButton ref="chartBtn"
          icon="fa-chart-line"
          @click="redirectToChartsPage"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import download from 'downloadjs';
  import API from '@/constants/api.constants';
  import utils from '@/features/utils.js';
  import RPMainDetails from '@/components/rp/details/RPMainDetails.vue';
  import { useModal } from 'vue-final-modal';
  import DownloadReport from '@/components/tools/DownloadReport.vue';
  import router from '@/router';
  import IconLoadingButton from '@/components/buttons/IconLoadingButton.vue';
  import { RP_STATE, RP_FIELD } from '@/constants/rp.constants.js';
  import ModalConfirm from '@/components/modals/ModalConfirm.vue';
  import RPCongrats from '@/components/rp/RPCongrats.vue';
  import Modal from '@/components/modals/ModalBasic.vue';

  const props = defineProps( {
    rp: { Object, required: true },
    readonly: { Boolean, required: false, default: false }
  } );
  const emits = defineEmits( [ 'changeRP', 'changeUsers' ] );

  const { t } = useI18n();
  const downloadReportBtn = ref( null );
  const chartBtn = ref( null );

  const actionBtn = ref( null );

  const close = () => {
    AXIOS.put( API.get_api( API.API_NAMES.RP_CLOSE, props.rp.id ), {}, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        changeRP( RP_FIELD.STATE );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  const reopen = () => {
    AXIOS.put( API.get_api( API.API_NAMES.RP_REOPEN, props.rp.id ), {}, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        changeRP( RP_FIELD.STATE );
        actionBtn.value.stopLoading();
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  const modalConfirm = useModal( {
    component: ModalConfirm,
    attrs: {
      header: t( 'Problem resolution closing confirmation' ),
      body: t( 'Are you sure you want to close this problem resolution ?' ),

      onConfirm() {
        close();
        modalConfirm.close();
        modal.open();
      },
      onCancel() {
        modalConfirm.close();
      },
      onClosed() {
        actionBtn.value != null ? actionBtn.value.stopLoading() : undefined;
      }
    }
  } );

  const modal = useModal( {
    component: Modal,
    slots: {
      content: {
        component: RPCongrats,
        attrs: {
          onCancel() {
            modal.close();
          },
          onGoHome() {
            modal.close();
            router.push( '/dashboard' );
          }
        }
      }
    }
  } );

  const changeRP = ( ...fields ) => {
    emits( 'changeRP', fields );
  };

  const changeUsers = () => {
    emits( 'changeUsers' );
  };

  const redirectToChartsPage = () => {
    router.push( '/charts/' + props.rp.id );
    chartBtn.value.stopLoading;
  };

  const clickDownloadReport = () => {
    let modal = useModal( {
      component: Modal,
      attrs: {
        header: t( 'Report customization' ),
        clickToClose: true,
        onClosed() {
          downloadReportBtn.value != null ? downloadReportBtn.value.stopLoading() : undefined;
        }
      },
      slots: {
        content: {
          component: DownloadReport,
          attrs: {
            rpDocumentsList: props.rp.rp_documents ?? [],
            async onDownload( desired_sections ) {
              desired_sections != null ? await download_report( desired_sections ) : undefined;
              modal.close();
            },
            onCancel() {
              modal.close();
            }
          }
        }
      }
    } );
    modal.open();
  };

  const download_report = async ( desired_sections ) => {
    if ( desired_sections != null ) {
      let url = API.get_api( API.API_NAMES.RP_DOWNLOAD_REPORT );
      await AXIOS.post( url, { id_rp: props.rp.id, locale: store.getters.getLocale, desired_sections: desired_sections }, { headers: { 'auth-token': store.getters.getToken }, responseType: 'blob' } )
        .then( ( response ) => {
          if ( response.status == '200' ) {
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            let content = response.headers[ 'content-type' ];
            download( response.data, 'RP_' + props.rp.index + '_Report', content );
          }
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };
</script>