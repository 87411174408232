<template>
  <GlobalIndicatorStd :start_date="start_date" :end_date="end_date" :change_date="eventDateChange">
    <template v-slot:header> {{ t( 'Counters' ) }} </template>
    <template v-slot:graph>
      <div class="flex h-full w-full place-content-center">
        <div class="flex flex-cols flex-wrap h-full w-full" v-if="medianProgressRPData && medianSecurringData && recurringRPData && lateActionsCounterData">

          <!-- Median Securring Action -->
          <div class="border-r-[1px] border-b-[1px] w-1/2 h-1/2">
            <GlobalMedianSecurringActionsChart class="p-0.5" :data="medianSecurringData"/>
          </div>

           <!-- Median Securring Action -->
          <div class="border-l-[1px] border-b-[1px] w-1/2 h-1/2">
            <GlobalLateActionsCounter class="p-0.5" :data="lateActionsCounterData"/>
          </div>

          <!-- Median Progess RP -->
          <div class="border-r-[1px] border-t-[1px] w-1/2 h-1/2">
            <GlobalMedianProgressRPChart class="p-0.5" :data="medianProgressRPData"/>
          </div>

          <!-- Coutner Recurring RP -->
          <div class="border-l-[1px] border-t-[1px] w-1/2 h-1/2">
            <GlobalRecurringRPCounter class="p-0.5" :data="recurringRPData"/>
          </div>

        </div>
        <Spinner v-else size="w-24"/>
      </div>
    </template>
  </GlobalIndicatorStd>
</template>

<script setup>
  import GlobalIndicatorStd from '@/components/indicators/global_indicators/GlobalIndicatorStd.vue';
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import moment from 'moment';
  import AXIOS from '@/features/axios.js';
  import API from '@/constants/api.constants';
  import GlobalRecurringRPCounter from '@/components/indicators/global_indicators/GlobalRecurringRPCounter.vue';
  import GlobalLateActionsCounter from '@/components/indicators/global_indicators/GlobalLateActionsCounter.vue';
  import GlobalMedianProgressRPChart from '@/components/indicators/global_indicators/GlobalMedianProgressRPChart.vue';
  import GlobalMedianSecurringActionsChart from '@/components/indicators/global_indicators/GlobalMedianSecurringActionsChart.vue';
  import Spinner from '@/components/object/SpinnerBasic.vue';

  const DEFAULT_DATES = [ moment().startOf( 'year' ).format( 'YYYY-MM-DD' ), moment().startOf( 'day' ).format( 'YYYY-MM-DD' ) ];
  const { t } = useI18n();

  let medianProgressRPData = ref( null );
  let medianSecurringData = ref( null );
  let recurringRPData = ref( null );
  let lateActionsCounterData = ref( null );
  let start_date = ref( DEFAULT_DATES[ 0 ] );
  let end_date = ref( DEFAULT_DATES[ 1 ] );

  const getGlobalCounters = () => {
    let url = '';

    // Get Data for Median Progress RP Chart
    url = API.get_api( API.API_NAMES.GLOBAL_INDICATORS_MEDIAN_PROGRESS_RP, start_date.value, end_date.value );
    AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        medianProgressRPData.value = response.data;
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );

    // Get Data for Median Securring Actions Chart
    url = API.get_api( API.API_NAMES.GLOBAL_INDICATORS_MEDIAN_SECURING_ACTIONS, start_date.value, end_date.value );
    AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        medianSecurringData.value = response.data;
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );

    // Get Data for Reccurung RP Counter
    url = API.get_api( API.API_NAMES.GLOBAL_INDICATORS_RECURRING_RP_COUNTER, start_date.value, end_date.value );
    AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        response.data.pourcent = Math.round( ( ( 100 * response.data.number_recurrent_rp ) / response.data.number_all_rp ) * 10 ) / 10;
        recurringRPData.value = response.data;
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );

    // Get Data for Late Actions Counter
    url = API.get_api( API.API_NAMES.GLOBAL_INDICATORS_LATE_ACTIONS_COUNTER, moment().format( 'YYYY-MM-DD' ) );
    AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        response.data.pourcent = Math.round( ( ( 100 * response.data.number_late_actions ) / response.data.number_all_actions ) * 10 ) / 10;
        lateActionsCounterData.value = response.data;
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };
  getGlobalCounters();

  function eventDateChange( params ) {
    medianProgressRPData.value = null;
    medianSecurringData.value = null;
    recurringRPData.value = null;
    lateActionsCounterData.value = null;
    start_date.value = moment( params[ 0 ] ).format( 'YYYY-MM-DD' );
    end_date.value = moment( params[ 1 ] ).format( 'YYYY-MM-DD' );
    getGlobalCounters();
  }
</script>