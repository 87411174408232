<template>
  <div class="flex-col space-y-5 min-w-[60rem]">
    <!-- Modal Body for Action -->
    <DisplayListRP :array="array" :event="eventClick" v-if="type === 'rp'"/>
    <DisplayListAction :array="array" :event="eventClick" v-if="type === 'action'"/>
  </div>
</template>

<script setup>
  import DisplayListRP from '@/components/object/DisplayListRP.vue';
  import DisplayListAction from '@/components/object/DisplayListAction.vue';
  import router from '@/router';

  defineProps( {
    type: { String, require: true, validator( value ) { return [ 'rp', 'action' ].includes( value ); } },
    array: { Array, required: true, default: [] }
  } );

  const emit = defineEmits( [ 'cancel' ] );

  const eventClick = function eventClick( params ) {
    if ( params ) {
      emit( 'cancel' );
      router.push( '/rp/' + params.id_rp_redirection );
    }
  };
</script>