<template>
  <div class="text-xl">
    <Field :placeholder="t( 'Why PLACEHOLDER' )"
      :field="template"
      fkey="title"
      @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.TOOL_5WHY_WHY"/>
  </div>

  <!-- Modal footer-->
  <div class="flex space-x-4 pt-4 justify-end">
    <div>
      <LoadingButton type="submit" :label="t( 'Add' )" @click="onAddNode" :disabled="!canAdd"/>
    </div>
    <div>
      <LoadingButton type="submit" :label="t( 'Cancel' )" @click="clickClose"/>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const emit = defineEmits( [ 'addNode', 'cancel' ] );

  const template = ref();

  const { t } = useI18n();

  const canAdd = computed( () => {
    return template.value != null && template.value !== '';
  } );

  const changeField = ( ...val ) => {
    if ( val && val[ 1 ] != null ) {
      template.value = val[ 1 ];
    }
  };

  const onAddNode = () => {
    if ( template.value != undefined && template.value != null && template.value.trim() != '' ) {
      emit( 'addNode', template.value );
    } else {
      clickClose();
    }
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>