<template>
  <div class="space-y-5">
    <h1 class="text-xl font-bold"> {{ t( 'Selected capitalising action' ) }} </h1>
    <div class="flex flex-col border border-2 border-gray-400 rounded gap-2 p-2">
      <div class="font-bold text-xl w-full max-w-[37rem]">
        <Field :field="capitalisingAction.title" fkey="title" :placeholder="t( 'Title PLACEHOLDER' )" :readonly="true"></Field>
      </div>
      <FieldArea :placeholder="t( 'Description PLACEHOLDER' )" :field="capitalisingAction.description" fkey="description" :readonly="true"></FieldArea>
    </div>
    <div class="flex flex-col items-start justify-center space-y-4">
      <h1 class="text-xl font-bold"> {{ t( 'Efficient actions list' ) }} </h1>
      <div v-if="actionsCheckList.length > 0" class="flex space-x-3">
        <div>
          <!-- Search field -->
          <input type="search" :placeholder="t( 'Search bar' ) + '...'" v-model="searchedValue"
          class="text-sm w-96 rounded-lg p-0.5 border-2 border-gray-200"/>
        </div>
        <!-- Radio buttons -->
        <div class="flex space-x-4 items-center accent-green-600/95">
          <div class="flex items-center space-x-1">
            <input type="radio" id="allCauses" name="causeFilter" @change="searchfilter = 'allCauses'"
            class="w-5 h-5 cursor-pointer" checked/>
            <label for="allCauses" class="cursor-pointer">{{ t( 'All actions' ) }}</label>
          </div>
          <div class="flex items-center space-x-1">
            <input type="radio" id="linkedActions" name="causeFilter" @change="searchfilter = 'linkedActions'"
            class="w-5 h-5 cursor-pointer"/>
            <label for="linkedActions" class="cursor-pointer">{{ t( 'Linked actions' ) }}</label>
          </div>
          <div class="flex items-center space-x-1">
            <input type="radio" id="unLinkedActions" name="causeFilter" @change="searchfilter = 'unLinkedActions'"
            class="w-5 h-5 cursor-pointer"/>
            <label for="unLinkedActions" class="cursor-pointer">{{ t( 'Unlinked actions' ) }}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- Capitalising actions scroll list -->
    <div v-if="actionsCheckList.length > 0" class="max-h-[400px] border border-2 border-gray-400 rounded overflow-auto">
      <RPCapitalisingActionListItem v-for="action in searchFilterActions()" :key="action" :capitalisingAction="action" :readonly="readonly" @click="check"></RPCapitalisingActionListItem>
    </div>

    <!-- Modal footer-->
    <div v-if="!readonly" class="flex space-x-4 justify-end">
      <div>
        <LoadingButton ref="saveBtn" type="submit" :label="t( 'Save' )" @click="save"/>
      </div>
      <div>
        <LoadingButton type="submit" :label="t( 'Cancel' )" @click="clickClose"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import AXIOS from '@/features/axios.js';
  import FieldArea from '@/components/object/FieldArea.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import API from '@/constants/api.constants';
  import RPCapitalisingActionListItem from '@/components/rp/capitalising_actions/RPCapitalisingActionListItem.vue';

  const props = defineProps( {
    readonly: { Boolean, required: false, default: true },
    actions: { Array, required: true, default: [] },
    capitalisingAction: { Object, required: true, default: {} }
  } );
  const emit = defineEmits( [ 'save', 'cancel' ] );

  const { t } = useI18n();
  const saveBtn = ref( null );
  const searchedValue = ref( '' );
  const searchfilter = ref( '' );

  const searchFilterActions = () => {
    let result = actionsCheckList.value;
    if ( searchedValue.value != '' ) {
      let search = searchedValue.value.toLowerCase();
      result = actionsCheckList.value.filter( ( action ) =>
        ( action.title ? String( action.title ).toLowerCase().includes( search ) : false )
      );
    }
    switch ( searchfilter.value ) {
    case 'linkedActions':
      result = result.filter( ( action ) => ( action ? action.checked : false ) );
      break;
    case 'unLinkedActions':
      result = result.filter( ( action ) => ( action ? !action.checked : false ) );
      break;
    default:
      break;
    }
    return result;
  };

  const actionsCheckList = ref(
    props.actions.map( ( action ) => {
      if ( props.capitalisingAction.action_actions && props.capitalisingAction.action_actions.find( ( item ) => item.id_action == action.id ) != undefined ) {
        action.checked = true;
      } else {
        action.checked = false;
      }
      return action;
    } ) );

  const check = ( action ) => {
    if ( !props.readonly && action != null
      && action.checked != null ) {
      action.checked = !action.checked;
    }
  };

  const clickClose = () => {
    emit( 'cancel' );
  };

  const save = () => {
    let promises = [];

    if ( props.capitalisingAction.id != undefined && props.capitalisingAction.id != null ) {
      let actionsToUnlink = actionsCheckList.value.filter( ( action ) => {
        if ( !action.checked && props.capitalisingAction.action_actions
          && props.capitalisingAction.action_actions.find( ( item ) => item.id_action == action.id ) != undefined ) {
          return action.id;
        }
      } ).map( ( action ) => action.id );

      let actionsToLink =  actionsCheckList.value.filter( ( action ) => {
        if ( action.checked && ( ( props.capitalisingAction.action_actions
          && props.capitalisingAction.action_actions.find( ( item ) => item.id_action == action.id ) == undefined )
          || ( props.capitalisingAction.action_actions == undefined || props.capitalisingAction.action_actions == null ) ) ) {
          return action.id;
        }
      } ).map( ( action ) => action.id );

      let url = API.get_api( API.API_NAMES.ACTIONS, props.capitalisingAction.id ) + 'actions';

      if ( Array.isArray( actionsToUnlink ) && actionsToUnlink.length > 0 ) {
        promises.push( new Promise ( ( resolve ) => AXIOS.delete( url, { data: { id_actions: actionsToUnlink }, headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            if ( response.status == '201' ) {
              store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            }
            utils.showAxiosError( response );
            resolve();
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
            resolve();
          } ) ) );
      }

      if ( Array.isArray( actionsToLink ) && actionsToLink.length > 0 ) {
        promises.push( new Promise ( ( resolve ) => AXIOS.post( url, { id_actions: actionsToLink }, { headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            if ( response.status == '201' ) {
              store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            }
            utils.showAxiosError( response );
            resolve();
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
            resolve();
          } ) ) );
      }
    }

    Promise.all( promises )
      .then( () => {
        promises.length == 0 ? emit( 'save', false ) : emit( 'save', true );
        saveBtn.value.stopLoading();
      } );
  };
</script>