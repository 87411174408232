<template>
  <div class="bg-white shadow shadow-shadowColor rounded group">
    <div class="focus-visible:outline-none rounded">
      <!-- Files DropZone for uploading -->
      <FilesDropZone ref="fileDropZone"
        :dropZoneText="t( 'Drag \'n\' drop some files here' )"
        :uploadBtnLabel="t( 'Add' )"
        :isFileEditable="true"
        @uploadFiles="uploadFiles">
      </FilesDropZone>
      <!-- Dropdown menu header -->
      <div @click="toggleExpander" class="flex flex-row content-center px-4 py-2 -mb-3 my-3 text-lg rounded hover:bg-tertiary-hover transition duration-200">
        <div class="font-bold">{{ t( 'Documents' ) }}</div>
        <div class="has-tooltip flex">
          <span class='tooltip'>{{ t( 'Documents total' )}}</span>
          <span class="items-center ml-3 px-1.5 pb-0.5 font-bold rounded-full bg-gray-500 text-white" :key="componentKey">{{ numberOfDocuments }}</span>
        </div>
        <div class="flex flex-row grow gap-3 justify-end">
          <font-awesome-icon v-if="isToggled" icon="fa-solid fa-chevron-right" rotation="90" size="xl"/>
          <font-awesome-icon v-else icon="fa-solid fa-chevron-right" size="xl" />
        </div>
      </div>
    </div>
    <!-- Dropdown menu body with files retrieved from the server -->
    <Collapse :when="isToggled" class="collapse-class mt-3">
      <div v-for="document in documents" :key="document" class="flex px-3 py-1.5">
        <FileItem :file="document" :isDownloadable="true" :isDeletable="true" @delete="deleteConfirm" @edit="editFile( document )"></FileItem>
      </div>
    </Collapse>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import store from '@/store';
  import utils from '@/features/utils.js';
  import AXIOS from '@/features/axios.js';
  import API from '@/constants/api.constants';
  import FilesDropZone from '@/components/file/FilesDropZone.vue';
  import FileItem from '@/components/file/FileItem.vue';
  import { useModal } from 'vue-final-modal';
  import FileEditionForm from '@/components/file/FileEditionForm.vue';
  import ModalConfirm from '@/components/modals/ModalConfirm.vue';
  import Modal from '@/components/modals/ModalBasic.vue';

  const props = defineProps( {
    id_rp: { String, required: true },
    readonly: { Boolean, required: false, default: false },
    documents: { Array, required: true, default: null }
  } );
  const emit = defineEmits( [ 'changeList', 'changeItem', 'removeItem' ] );

  const { t } = useI18n();
  const isToggled = ref( store.getters.getExpanders.documents ?? true );
  const componentKey = ref ( 0 );
  const curFile = ref( null );
  const fileDropZone = ref( null );
  const numberOfDocuments = computed( () => {
    let total = 0;
    if ( props.documents ) {
      for ( let i = 0 ; i < props.documents.length ; i++ ) {
        total++;
      }
    }
    return total;
  } );

  const toggleExpander = () => {
    store.dispatch( 'setExpanders', { documents: !isToggled.value } );
    isToggled.value = store.getters.getExpanders.documents;
  };
  defineExpose( { toggleExpander, isToggled } );

  const trimTitle = ( title ) => {
    if ( title.length > 36 ) {
      return title.toString( ).substring( 0, 36 );
    }
    return title;
  };

  const trimDescription = ( description ) => {
    if ( description.length > 500 ) {
      return description.toString( ).substring( 0, 500 );
    }
    return description;
  };

  const getFormData = ( file ) => {
    let formData = new FormData();
    formData.append( 'title', file.title );
    formData.append( 'description', file.description );
    formData.append( 'file', file.file, file.filename );
    return formData;
  };

  const uploadFiles = ( filesList ) => {
    let url = API.get_api( API.API_NAMES.RP_DOCUMENTS, props.id_rp );
    let promises = [];

    filesList.forEach( ( file ) => {
      promises.push( new Promise ( ( resolve ) => AXIOS.post( url, getFormData( file ), { headers: { 'auth-token': store.getters.getToken, 'Content-Type': 'multipart/form-data' } } )
        .then( ( response ) => {
          if ( response.status == '200' ) {
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          }
          utils.showAxiosError( response );
          resolve();
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
          resolve();
        } ) ) );
    } );

    Promise.all( promises )
      .then( () => {
        fileDropZone.value.clearFilesList();
        fileDropZone.value.stopLoading();
        changeList();
      } );
  };

  const deleteConfirm = ( file ) => {
    curFile.value = file;
    let confirmModal = useModal( {
      component: ModalConfirm,
      attrs: {
        header: t( 'File deletion confirmation' ),
        body: t( 'Are you sure you want to delete this file ?' ),

        onConfirm() {
          deleteFile( curFile.value );
          confirmModal.close();
        },
        onCancel() {
          confirmModal.close();
        }
      }
    } );
    confirmModal.open();
  };

  const deleteFile = ( file ) => {
    let url = API.get_api( API.API_NAMES.RP_DOCUMENTS, file.id );
    AXIOS.delete( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          curFile.value = null;
          removeItem( file.id );
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  const editFile = ( clickedFile ) => {
    let modal = useModal( {
      component: Modal,
      attrs: { header: t( 'Edit a file' ) },
      slots: {
        content: {
          component: FileEditionForm,
          attrs: {
            file: clickedFile,
            onConfirm( fileChanged ) {
              changeFile( fileChanged );
              modal.close();
            },
            onCancel() {
              modal.close();
            }
          }
        }
      }
    } );
    modal.open();
  };

  const changeFile = ( file ) => {
    let url = API.get_api( API.API_NAMES.DOCUMENTS, file.id );
    AXIOS.put( url, { title: trimTitle( file.title ), description: trimDescription( file.description ) }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          changeItem( file.id );
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  const changeList = () => {
    emit( 'changeList' );
  };

  const changeItem = ( id ) => {
    emit( 'changeItem', id );
  };

  const removeItem = ( id ) => {
    emit( 'removeItem', id );
  };
</script>

<style lang="scss" scoped>
  .has-tooltip:hover .tooltip {
    @apply visible opacity-100 z-50 -mx-1 my-8;
  }
</style>