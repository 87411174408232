<template>
  <fieldset class="min-w-[350px] h-fit border shadow-lg opacity-95 rounded-xl space-y-4 rounded p-4 -ml-[1px]" v-if="selectedService">
    <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div class="flex h-full w-full sm:col-span-6 justify-between">
        <span class="font-bold"> {{ t( 'Modify' ) }} </span>
        <font-awesome-icon icon="fa-solid fa-xmark" class="fa-xl cursor-pointer" @click="close()" />
      </div>
      <div class="sm:col-span-6">
        <label for="first-name" class="block text-lg font-medium leading-6 text-gray-900">{{ t( 'Service name' ) }}</label>
        <div class="mt-2">
          <Field :fkey="selectedService.id" :field="selectedService.name"
            :readonly="false" @change="( id, name ) => updateService( id, name )"
            class="border rounded-lg p-1"
            :maxlength="MAX_INPUT_LENGTH.SERVICE_NAME"/>
        </div>
      </div>

      <button type="button" class="sm:col-span-6 w-fit py-1 px-2 bg-red-600 hover:bg-red-700 text-white text-sm font-semibold rounded-md shadow justify-self-end	" @click="deleteService()"> <font-awesome-icon icon="fa-solid fa-trash-can" /> {{ t( 'Delete' ) }} </button>
    </div>
  </fieldset>
</template>

<script setup>
  import Field from '@/components/object/FieldBasic.vue';
  import ModalConfirm from '@/components/modals/ModalConfirm.vue';
  import { useModal } from 'vue-final-modal';
  import { useI18n } from 'vue-i18n';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const { t } = useI18n();
  const emit = defineEmits( [ 'update', 'close', 'delete' ] );
  const props = defineProps( {
    selectedService: { Object, require: true },
    services: { Array, require: true }
  } );

  function updateService( id, body ) {
    emit( 'update', id, body );
  }

  function deleteService() {
    deletingConfirmModal.open();
  }

  function close() {
    emit( 'close' );
  }

  let bodyPopup = t( 'Users of this service will not be on any service', [ props.selectedService.users.length ] );

  if ( props.selectedService.users && props.selectedService.users.length > 0 ) {
    bodyPopup = '<div> <span class="has-tooltip-2">'
      + t( 'Users of this service will not be on any service', [ props.selectedService.users.length ] )
      + '<ul class="tooltip">';
    props.selectedService.users.forEach( ( user ) => {
      bodyPopup += '<li>' + user.fullname + '</li>';
    } );
    bodyPopup += '</ul></span></div>';
  }

  const deletingConfirmModal = useModal( {
    component: ModalConfirm,
    attrs: {
      header: t( 'Are you sure you want to delete the x service ?', [ props.selectedService.name ] ),
      body: bodyPopup,

      onConfirm() {
        emit( 'delete', props.selectedService.id );
        emit( 'close' );
        deletingConfirmModal.close();
      },
      onCancel() {
        deletingConfirmModal.close();
      }
    }
  } );
</script>