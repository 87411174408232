<template>
  <div class="flex flex-col text-xl space-y-5">
    <!-- Modal Body -->
    <Field :title="t( 'Section label' )"
      :placeholder="t( 'Label' )"
      :field="template.label"
      fkey="label"
      @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_TEMPLATE_LABEL"/>
    <FieldArea :title="t( 'Is field description' )"
      :placeholder="t( 'Is field description' )"
      :field="template.is_desc"
      fkey="is_desc"
      class="text-lg"
      @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_TEMPLATE_IS_DESC"/>
    <FieldArea :title="t( 'Is not field description' )"
      :placeholder="t( 'Is not field description' )"
      :field="template.is_not_desc"
      fkey="is_not_desc"
      class="text-lg"
      @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_TEMPLATE_IS_NOT_DESC"/>
    <FieldArea :title="t( 'Particularity field description' )"
      :placeholder="t( 'Particularity field description' )"
      :field="template.particularities_desc"
      fkey="particularities_desc"
      class="text-lg"
      @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_TEMPLATE_PARTICULARITIES_DESC"/>
    <FieldArea :title="t( 'Change field description' )"
      :placeholder="t( 'Change field description' )"
      :field="template.changes_desc"
      fkey="changes_desc"
      class="text-lg"
      @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.IS_IS_NOT_TEMPLATE_CHANGES_DESC"/>

    <!-- Modal footer-->
    <div class="flex space-x-4 justify-end">
      <div>
        <LoadingButton :label="t( 'Add' )" @click="addTemplate" :disabled="!canAdd"/>
      </div>
      <div>
        <LoadingButton :label="t( 'Cancel' )" @click="clickClose"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import FieldArea from '@/components/object/FieldArea.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const emit = defineEmits( [ 'addTemplate', 'cancel' ] );

  const template = ref( {} );

  const { t } = useI18n();

  const canAdd = computed( () => {
    return template.value != null
      && template.value.label != null
      && template.value.label.trim() !== '';
  } );

  const changeField = ( ...val ) => {
    if ( val && val[ 0 ] && val[ 0 ] != '' && val[ 1 ] != null ) {
      template.value[ val[ 0 ] ] = val[ 1 ];
    }
  };

  const addTemplate = () => {
    if ( canAdd.value ) {
      emit( 'addTemplate', template.value );
    } else {
      clickClose();
    }
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>