<template>
  <div class="rp-item-card" :key="componentKey">
    <div class="flex space-x-3 self-end -mb-8 -mt-2 -mr-2">
      <div class="has-tooltip flex">
        <span class='tooltip'>{{ t( 'Link capitalising action to efficient actions' )}}</span>
        <button class="hover:bg-tertiary-hover rounded-full" @click="clickLinkAction"  >
          <font-awesome-icon icon="fa-solid fa-lightbulb" size="xl" class="px-2 py-2"/>
        </button>
      </div>
      <button v-if="!readonly" class="hover:bg-tertiary-hover rounded-full h-8" @click="remove">
        <font-awesome-icon icon="fa-solid fa-xmark" size="xl" class="px-2 py-1"/>
      </button>
    </div>
    <div class="font-bold text-xl w-full max-w-[37rem] pt-2">
      <Field :field="capitalisingAction.title" fkey="title"
        :placeholder="t( 'Title PLACEHOLDER' )" :readonly="readonly" @change="changeField"
        :maxlength="MAX_INPUT_LENGTH.ACTION_TITLE"/>
    </div>
    <FieldArea class="flex-auto"
      :placeholder="t( 'Description PLACEHOLDER' )" :field="capitalisingAction.description"
      fkey="description" :readonly="readonly" @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.ACTION_DESCRIPTION"/>
    <div v-for="user in capitalisingAction.action_users" :key="user" >
      <RPCapitalisingActionUserItem :users="auth_users" :item="user"
        :readonly="readonly" @changeItem="changeItem"/>
    </div>
    <div v-if="!readonly
      && ( capitalisingAction.action_users == undefined
      || ( capitalisingAction.action_users && capitalisingAction.action_users.length == 0 ) )
      && ( users && users.length > 0 )
      && !isBusy" :key="capitalisingAction.action_users">
      <SmallButtonAddItem :tooltip="t( 'Add a pilot' )" @add-item="addActionUser"/>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import store from '@/store';
  import utils from '@/features/utils.js';
  import Field from '@/components/object/FieldBasic.vue';
  import SmallButtonAddItem from '@/components/buttons/SmallButtonAddItem.vue';
  import RPCapitalisingActionUserItem from '@/components/rp/capitalising_actions/RPCapitalisingActionUserItem.vue';
  import API from '@/constants/api.constants';
  import FieldArea from '@/components/object/FieldArea.vue';
  import LinkCapitalisingActionToAction from '@/components/rp/capitalising_actions/LinkCapitalisingActionToAction.vue';
  import { useModal } from 'vue-final-modal';
  import AXIOS from '@/features/axios.js';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';
  import Modal from '@/components/modals/ModalBasic.vue';

  const props = defineProps( {
    users: { Array, required: true },
    capitalisingAction: { Object, required: true },
    readonly: { Boolean, required: false, default: false },
    actions: { Array, required: true }
  } );
  const emit = defineEmits( [ 'removeItem', 'changeItem' ] );
  const { t } = useI18n();
  const auth_users = ref ( [] );
  const isBusy = ref( false );

  const componentKey = ref ( 0 );
  const forceRerender = () => {
    componentKey.value += 1;
  };

  const busy = () => {
    isBusy.value = !isBusy.value;
  };

  if ( props.users && props.users.length > 0 ) {
    for ( let i = 0; i < props.users.length; i++ ) {
      auth_users.value.push( {
        val: props.users[ i ].val,
        text: props.users[ i ].text,
        disabled: false
      } );
    }
  }

  const changeField = ( fkey, val ) => {
    let url = API.get_api( API.API_NAMES.ACTIONS, props.capitalisingAction.id );
    AXIOS.put( url, { [ fkey ]: val }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          changeItem();
        } else {
          forceRerender();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const remove = () => {
    let url = API.get_api( API.API_NAMES.RP_CAPITALISING_ACTION, props.capitalisingAction.id );
    AXIOS.delete( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        utils.analyse_axios_request( response );
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          removeItem();
        } else {
          forceRerender();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const addActionUser = () => {
    if ( auth_users.value.length > 0 ) {
      let idx = -1;
      if ( props.capitalisingAction.action_users ) {
        let ids = props.capitalisingAction.action_users.map( ( item ) => { return item.user.id; } );
        for ( let i = 0; i < auth_users.value.length; i++ ) {
          if ( !ids.includes( auth_users.value[ i ].val ) ) {
            idx = i;
          }
        }
      } else {
        idx = 0;
      }
      if ( idx > -1 ) {
        busy();
        let url = API.get_api( API.API_NAMES.ACTIONS, props.capitalisingAction.id, 'users' );
        AXIOS.post( url, { id_users: auth_users.value[ idx ].val }, { headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            if ( response.status == '201' ) {
              store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
              changeItem();
            } else {
              forceRerender();
            }
            busy();
            utils.showAxiosError( response );
          } )
          .catch( ( err ) => {
            forceRerender();
            utils.showCatch( err );
            busy();
          } );
      }
    }
  };

  const clickLinkAction = () => {
    let modal = useModal( {
      component: Modal,
      attrs: { header: t( 'Link capitalising action to efficient actions' ) },
      slots: {
        content: {
          component: LinkCapitalisingActionToAction,
          attrs: {
            readonly: props.readonly,
            actions: props.actions,
            capitalisingAction: props.capitalisingAction,

            onCancel() {
              modal.close();
            },
            onSave( changes ) {
              if ( changes != undefined && changes != null && changes ) {
                changeItem();
              }
              modal.close();
            }
          }
        }
      }
    } );
    modal.open();
  };

  const changeItem = () => {
    emit( 'changeItem', props.capitalisingAction.id );
  };

  const removeItem = () => {
    emit( 'removeItem', props.capitalisingAction.id );
  };
</script>