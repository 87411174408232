<template>
  <div class="space-y-6 w-[40rem]">
    <div class="text-center font-bold text-xl">{{ title }}</div>
    <div class="space-y-2">
      <FieldDateTime :title="t('When detected')"
        :field="t6w.desc_when_detected"
        fkey="desc_when_detected"
        :readonly="readonly"
        @change="changeField"
        @clear="changeField"
        :key="componentKey"/>
      <Field :title="t('Who')"
        :field="t6w.desc_who"
        fkey="desc_who"
        :readonly="readonly"
        @change="changeField"
        :key="componentKey"
        :maxlength="MAX_INPUT_LENGTH.TOOL_6W_DESC_WHO"/>
      <Field :title="t('What')"
        :field="t6w.desc_what"
        fkey="desc_what"
        :readonly="readonly"
        @change="changeField"
        :key="componentKey"
        :maxlength="MAX_INPUT_LENGTH.TOOL_6W_DESC_WHAT"/>
      <Field :title="t('Where')"
        :field="t6w.desc_where"
        fkey="desc_where"
        :readonly="readonly"
        @change="changeField"
        :key="componentKey"
        :maxlength="MAX_INPUT_LENGTH.TOOL_6W_DESC_WHERE"/>
      <Field :title="t('How')"
        :field="t6w.desc_how"
        fkey="desc_how"
        :readonly="readonly"
        @change="changeField"
        :key="componentKey"
        :maxlength="MAX_INPUT_LENGTH.TOOL_6W_DESC_HOW"/>
      <FieldDateTime :title="t('When appeared')"
        :field="t6w.desc_when_appeared"
        fkey="desc_when_appeared"
        :readonly="readonly"
        @change="changeField"
        @clear="changeField"
        :key="componentKey"/>
      <div class="text-m font-bold text-secondary-text">
        <p>{{ t( 'Suggested problem description' ) }}</p>
      </div>
      <div class="text-blue-700 w-full break-normal whitespace-pre-line" v-html="fullDescription"></div>
    </div>
    <div v-if="!readonly" class="flex justify-end">
      <div>
        <LoadingButton :label="t('Use description')" @click="clickApply"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, watchEffect } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import moment from 'moment-timezone';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import Field from '@/components/object/FieldBasic.vue';
  import FieldDateTime from '@/components/object/FieldDateTime.vue';
  import API from '@/constants/api.constants';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';

  const props = defineProps( {
    tool_6w: { Object, required: true },
    title: { String, required: true },
    id_rp: { String, required: true },
    readonly: { Boolean, required: false, default: false }
  } );
  const emit = defineEmits( [ 'click-close', 'click-apply', 'update' ] );
  const { t } = useI18n();
  const fullDescription = ref( '' );
  const t6w = ref();
  const idRP = ref();

  const componentKey = ref ( 0 );
  const forceRerender = () => {
    componentKey.value += 1;
  };

  watchEffect( () => ( t6w.value = props.tool_6w ) );
  watchEffect( () => ( idRP.value = props.id_rp ) );

  const init = () => {
    moment.locale( store.getters.getLocale );
    if ( !t6w.value ) {
      t6w.value = {};
    }
    createDescription();
  };

  const clickClose = () => {
    emit( 'click-close' );
  };

  const update = () => {
    emit( 'update', t6w.value );
  };

  const clickApply = () => {
    emit( 'click-apply', fullDescription.value );
    clickClose();
  };

  const changeField = ( fkey, val ) => {
    t6w.value[ fkey ] = val;
    createDescription();
    let axiosCallback = AXIOS.put;
    let body = {
      desc_who: t6w.value.desc_who ?? undefined,
      desc_what: t6w.value.desc_what ?? undefined,
      desc_where: t6w.value.desc_where ?? undefined,
      desc_how: t6w.value.desc_how ?? undefined,
      desc_when_appeared: t6w.value.desc_when_appeared ?? undefined,
      desc_when_detected: t6w.value.desc_when_detected ?? undefined
    };
    let axiosUrlId = t6w.value.id;
    if ( !t6w.value.id ) {
      axiosCallback = AXIOS.post;
      body.id_rp = idRP.value;
      axiosUrlId = '';
    }
    delete body.id;
    let url = API.get_api( API.API_NAMES.TOOL_6W, axiosUrlId );
    axiosCallback( url, body, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        if ( response.status == '200' ) {
          t6w.value.id = response.data.id;
        } else if ( response.status != '201' ) {
          forceRerender();
        }
        update();
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const createDescription = () => {
    let description = t( 'construct_suggestec_description', {
      whenddate: moment( t6w.value.desc_when_detected ).format( 'LL' ),
      whendtime: moment( t6w.value.desc_when_detected ).format( 'LT' ),
      who: t6w.value.desc_who,
      what: t6w.value.desc_what,
      where: t6w.value.desc_where,
      how: t6w.value.desc_how,
      why: t6w.value.desc_why,
      whenadate: moment( t6w.value.desc_when_appeared ).format( 'LL' ),
      whenatime: moment( t6w.value.desc_when_appeared ).format( 'LT' ),
    } );
    fullDescription.value = description;
  };

  init();
</script>