<template>
  <div class="w-[95vw] h-[90vh]">
    <!-- Tree chart -->
    <div class="w-full h-[90%] border-2 border-gray-300 rounded-xl">
      <RP5WhyTreeChart @selectNode="selectNode" :data="toolData"/>
    </div>
    <!-- Selected node view -->
    <div v-if="selectedNode != null" :key="componentKey"
      class="absolute animate-blinkingBg bottom-4 left-4 bg-white z-100 flex-col border-2 border-gray-300 rounded-lg w-[30rem] p-2 space-y-2">
      <div class="relative z-50">
        <div class="z-50 absolute top-1 right-3 hover:bg-tertiary-hover rounded-full w-fit -mt-1 -mr-2" @click="unSelectNode">
          <button class="z-50"><font-awesome-icon icon="fa-solid fa-xmark" size="xl" class="z-50 px-2 py-1"/></button>
        </div>
      </div>
      <h1 class="flex justify-center font-bold text-xl">{{ t( 'Selected node' ) }}</h1>
      <Field v-if="selectedNode.id != null" class="z-50"
        :field="selectedNode.name" @change="changeNode"
        :readonly="readonly || selectedNode.id == null" :key="selectedNode.name"
        :maxlength="MAX_INPUT_LENGTH.TOOL_5WHY_WHY"/>
      <div class="flex space-x-3 z-50">
        <div v-if="selectedNode.depth != null && selectedNode.depth < 5" class="z-50">
          <LoadingButton ref="addNodeBtn" type="submit" :label="t( 'Add a Why' )" @click="clickNewNode"/>
        </div>
        <div v-if="selectedNode.depth != null && selectedNode.depth != 0" class="z-50">
          <LoadingButton ref="deleteNodeBtn" type="submit" :label="t( 'Delete Why' )" @click="clickDelete"/>
        </div>
      </div>
    </div>
    <!-- Export as causes button -->
    <div v-if="!readonly && tool5WhyData != null && tool5WhyData.children.length > 0" class="flex absolute bottom-2 right-2 space-x-4 pt-2 justify-end z-50 items-center">
      <div class="z-50">
        <LoadingButton ref="exportBtn" type="submit" :label="t( 'Create as causes' )" @click="clickExport"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, watchEffect } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import Field from '@/components/object/FieldBasic.vue';
  import API from '@/constants/api.constants';
  import RP5WhyTreeChart from '@/components/indicators/rp_indicators/RP5WhyTreeChart.vue';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import { useModal } from 'vue-final-modal';
  import New5WhyNodeForm from '@/components/tools/5why/New5WhyNodeForm.vue';
  import ModalConfirm from '@/components/modals/ModalConfirm.vue';
  import Export5WhyToCause from '@/components/tools/5why/Export5WhyToCause.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';
  import Modal from '@/components/modals/ModalBasic.vue';

  const props = defineProps( {
    tool5WhyData: { Object, required: false },
    id_rp: { String, required: true },
    readonly: { Boolean, required: false, default: false }
  } );

  const emit = defineEmits( [ 'click-close', 'change5Why', 'changeCauseList' ] );

  const { t } = useI18n();
  const selectedNode = ref( null );

  const addNodeBtn = ref( null );
  const deleteNodeBtn = ref( null );
  const exportBtn = ref( null );

  const toolData = ref( null );

  const componentKey = ref( 0 );
  const forceRerender = () => {
    componentKey.value += 1;
  };

  const formatNode = ( node ) => {
    return {
      id: node.id ?? undefined,
      name: node.id ?? '',
      value: node.name ?? '',
      depth: node.depth ?? undefined,
      register_date: node.register_date ?? '',
      last_activity: node.last_activity ?? '',
      children: node.children
        ? node.children.map( ( item ) => {
          return formatNode( item );
        } )
        : []
    };
  };

  const defaultNodeFormat = ( node ) => {
    let res = {
      id: node.id ?? undefined,
      name: node.value ?? '',
      value: node.name ?? '',
      depth: node.depth ?? undefined,
      register_date: node.register_date ?? '',
      last_activity: node.last_activity ?? '',
      children: node.children
        ? node.children.map( ( item ) => {
          return defaultNodeFormat( item );
        } )
        : []
    };
    delete res.value;
    return res;
  };

  watchEffect( () => ( toolData.value = formatNode( props.tool5WhyData ) ) );

  const isNodeWithoutChildren = ( node ) => {
    return ( node != null
      && node.children != null
      && Array.isArray( node.children )
      && node.children.length === 0
    );
  };

  const selectNode = ( node ) => {
    if ( node != null ) {
      selectedNode.value = defaultNodeFormat( node );
      forceRerender();
    }
  };

  watchEffect( () => {
    if ( isNodeWithoutChildren( toolData.value ) ) {
      selectNode( toolData.value );
    }
  } );

  const getNodeById = ( node, id ) => {
    if ( node != null && id != null ) {
      if ( node.id != null && node.id === id ) {
        return node;
      } else if ( node.children != null && Array.isArray( node.children ) ) {
        for ( let i = 0 ; i < node.children.length ; i++ ) {
          let temp = getNodeById( node.children[ i ], id );
          if ( temp != null ) {
            return temp;
          }
        }
      }
    }
    return null;
  };

  const unSelectNode = () => {
    if ( isNodeWithoutChildren( toolData.value ) ) {
      selectNode( toolData.value );
    } else {
      selectedNode.value = null;
    }
  };

  const changeNode = ( ...newLabel ) => {
    if ( selectedNode.value != null && selectedNode.value.id != null
      && newLabel != null && Array.isArray( newLabel ) && newLabel.length >= 2
      && newLabel[ 1 ] != null && newLabel[ 1 ] != '' ) {
      let url = API.get_api( API.API_NAMES.TOOL_5WHY, selectedNode.value.id );
      let body = {
        why: newLabel[ 1 ]
      };
      AXIOS.put( url, body, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          emit( 'change5Why' );
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    } else {
      forceRerender();
    }
  };

  const getTreeLeaves = ( node ) => {
    let leaves = [];
    if ( node != null && ( ( node.children != null &&  Array.isArray( node.children ) && node.children.length <= 0 ) || !( node.children != null ) ) ) {
      return [ node ];
    }
    if ( node != null && node.children != null && Array.isArray( node.children ) && node.children.length > 0 ) {
      node.children.forEach( ( element ) => {
        leaves.push( ...getTreeLeaves( element ) );
      } );
    }
    return leaves;
  };

  const clickClose = () => {
    emit( 'click-close' );
  };

  const clickNewNode = () => {
    let newNodeModal = useModal( {
      component: Modal,
      attrs: {
        header: t( 'Add a Why' ),
        onClosed() {
          addNodeBtn.value ? addNodeBtn.value.stopLoading() : undefined;
        }
      },
      slots: {
        content: {
          component: New5WhyNodeForm,
          attrs: {
            onCancel() {
              newNodeModal.close();
            },
            onAddNode( name ) {
              let url = API.get_api( API.API_NAMES.TOOL_5WHY );
              let body = {
                id_rp: props.id_rp,
                why: name,
                parent_id: selectedNode.value.id ?? undefined
              };
              AXIOS.post( url, body, { headers: { 'auth-token': store.getters.getToken } } )
                .then( ( response ) => {
                  store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
                  newNodeModal.close();
                  emit( 'change5Why' );
                  utils.showAxiosError( response );
                } )
                .catch( ( err ) => {
                  newNodeModal.close();
                  utils.showCatch( err );
                } );
            }
          }
        }
      }
    } );
    newNodeModal.open();
  };

  const clickDelete = () => {
    if ( selectedNode.value != null && selectedNode.value.id != null ) {
      let nodeToDelete = getNodeById( props.tool5WhyData, selectedNode.value.id );
      if ( nodeToDelete != null && nodeToDelete.children != null
        && Array.isArray( nodeToDelete.children )
        && nodeToDelete.children.length > 0 ) {
        let deleteConfirmModal = useModal( {
          component: ModalConfirm,
          attrs: {
            header: t( 'Why deletion' ),
            body: t( 'Why deletion BODY' ),
            labelOpt1: t( 'Confirm' ),
            labelOpt2: t( 'Cancel' ),
            onCancel() {
              deleteConfirmModal.close();
            },
            onConfirm() {
              deleteNode();
              deleteConfirmModal.close();
            },
            onClosed() {
              deleteNodeBtn.value ? deleteNodeBtn.value.stopLoading() : undefined;
            },
          }
        } );
        deleteConfirmModal.open();
      } else {
        deleteNode();
      }
    }
  };

  const deleteNode = () => {
    if ( selectedNode.value != null && selectedNode.value.id != null ) {
      let url = API.get_api( API.API_NAMES.TOOL_5WHY, selectedNode.value.id );
      AXIOS.delete( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          deleteNodeBtn.value ? deleteNodeBtn.value.stopLoading() : undefined;
          unSelectNode();
          emit( 'change5Why' );
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          deleteNodeBtn.value ? deleteNodeBtn.value.stopLoading() : undefined;
          utils.showCatch( err );
        } );
    }
  };

  const clickExport = () => {
    let modal = useModal( {
      component: Modal,
      attrs: {
        header: t( 'Lists of why to create to causes' ),
        onClosed() {
          exportBtn.value ? exportBtn.value.stopLoading() : undefined;
        }
      },
      slots: {
        content: {
          component: Export5WhyToCause,
          attrs: {
            _5WhyList: getTreeLeaves( props.tool5WhyData ),
            id_rp: props.id_rp,

            onCancel() {
              modal.close();
            },
            async onSave( causesCreated ) {
              await modal.close();
              causesCreated ? emit( 'changeCauseList' ) : undefined;
              clickClose();
            }
          }
        }
      }
    } );
    modal.open();
  };
</script>