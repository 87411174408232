<template>
  <div class="flex-col space-y-5">
    <FieldArea :title="t( 'Note' )"
      class="text-xl"
      :placeholder="t( 'Description PLACEHOLDER' )"
      :field="note"
      fkey="note"
      @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.RP_NOTE"/>

    <div class="flex space-x-4 justify-end">
      <div>
        <LoadingButton :label="t( 'Add' )" @click="addNote" :disabled="!canAdd"/>
      </div>
      <div>
        <LoadingButton :label="t( 'Cancel' )" @click="clickClose"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import FieldArea from '@/components/object/FieldArea.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const emit = defineEmits( [ 'addNote', 'cancel' ] );

  const note = ref();

  const { t } = useI18n();

  const canAdd = computed( () => {
    return note.value != null && note.value.trim() !== '';
  } );

  const changeField = ( ...val ) => {
    if ( val && val[ 1 ] != null ) {
      note.value = val[ 1 ];
    }
  };

  const addNote = () => {
    if ( canAdd.value ) {
      emit( 'addNote', note.value );
    } else {
      clickClose();
    }
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>