<template>
  <div class="flex-col space-y-5">
    <!-- Modal Body -->
    <div class="text-xl">
      <Field :title="t( 'Template title' )"
        :placeholder="t( 'Title PLACEHOLDER' )"
        :field="template"
        fkey="title"
        @change="changeField"
        :maxlength="MAX_INPUT_LENGTH.CAPITALIZATION_TEMPLATE_TITLE"/>
    </div>

    <!-- Modal footer-->
    <div class="flex space-x-4 justify-end">
      <div>
        <LoadingButton :label="t( 'Add' )" @click="addTemplate" :disabled="!canAdd"/>
      </div>
      <div>
        <LoadingButton :label="t( 'Cancel' )" @click="clickClose"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const emit = defineEmits( [ 'addTemplate', 'cancel' ] );

  const template = ref();

  const { t } = useI18n();

  const canAdd = computed( () => {
    return template.value != null && template.value.trim() !== '';
  } );

  const changeField = ( ...val ) => {
    if ( val && val[ 1 ] != null ) {
      template.value = val[ 1 ];
    }
  };

  const addTemplate = () => {
    if ( canAdd.value ) {
      emit( 'addTemplate', template.value );
    } else {
      clickClose();
    }
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>