<template>
  <VueFinalModal
    :overlay-transition="overlayTransition"
    :content-transition="contentTransition"
    :click-to-close="clickToClose"
    :esc-to-close="escToClose"
    :lock-scroll="lockScroll"
    class="modal"
    :class="{'animate-zoomInOut': animated}"
    @animationend="animated = false"
    @click.self="canClickToClose"
    @keyup.esc="canEscToClose"
  >
  <template #default="{ close }">
    <div class="modal-content"
      :class="{ 'overflow-y-auto': overflowY }">
      <div v-if="header" class="modal-header">
        <div class="has-tooltip flex">
          {{ header }}
          <div v-if="headerTooltip" class="-mt-2">
            <font-awesome-icon icon="fa-solid fa-circle-info" size="xs" style="color: #2563eb;"/>
          </div>
          <span v-if="headerTooltip" class="tooltip mt-8" v-html="headerTooltip"></span>
        </div>
      </div>
      <button v-if="showCloseButton" class="modal-close-button" @click="() => close()">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
      <slot name="content" @close="() => close()"/>
    </div>
  </template>
  </VueFinalModal>
</template>

<script setup>
  import { ref } from 'vue';
  import { VueFinalModal } from 'vue-final-modal';

  const props = defineProps( {
    escToClose: { Boolean, required: false, default: true },
    clickToClose: { Boolean, required: false, default: false },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false, default: 'vfm-fade' },
    contentTransition: { String, required: false, default: 'vfm-fade'  },
    showCloseButton: { Boolean, required: false, default: true },
    header: { String, required: false, default: null },
    headerTooltip: { String, required: false, default: null },
    overflowY: { Boolean, required: false, default: true }
  } );

  const animated = ref( false );

  const animate = () => {
    animated.value = true;
  };

  const canEscToClose = () => {
    if ( !props.escToClose ) {
      animate();
    }
  };

  const canClickToClose = () => {
    if ( !props.clickToClose ) {
      animate();
    }
  };
</script>

<style lang="scss">
  .modal-close-button {
    @apply absolute top-0 right-0;
    @apply px-2 py-1 z-50;
    @apply hover:bg-fourth-hover;
    @apply rounded-full;
  }

  .modal {
    @apply flex justify-center items-center;
  }

  .modal-content {
    @apply relative;
    @apply p-2 bg-white rounded-lg;
    @apply max-h-screen;
    @apply min-w-[30rem];
  }

  .modal-header {
    @apply flex justify-center;
    @apply text-2xl font-bold pb-4 px-10;
  }
</style>
