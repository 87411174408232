<template>
    <div class="space-y-4">
      <!-- Modal Body -->
      <h1 class="text-xl font-bold"> {{ t( 'Selected action') }} </h1>
      <div class="flex flex-col pl-2 border border-2 border-gray-400 rounded gap-2 p-2">
        <div class="font-bold text-xl w-full max-w-[37rem]">
          <Field :field="action.title" fkey="title" :placeholder="t( 'Title PLACEHOLDER' )" :readonly="true"></Field>
        </div>
        <FieldArea :placeholder="t( 'Description PLACEHOLDER' )" :field="action.description" fkey="description" :readonly="true"></FieldArea>
      </div>
      <div class="flex flex-col items-start justify-center space-y-4">
        <h1 class="text-xl font-bold"> {{ t( 'List of impact studies with a response' ) }} </h1>
        <div v-if="impactStudiesCheckList.length > 0" class="flex space-x-3">
          <div class="flex items-center w-full basis-1/2">
            <select class="h-[30px] w-max p-1 text-sm text-gray-800 border-2 border-gray-200 bg-gray-200 rounded-l-lg cursor-pointer" @change="selectChanged($event)">
              <option value="" class="bg-gray-200">{{ t( 'All fields' ) }}</option>
              <option v-for="item in headers" :key="item.value" :value="item.value" class="bg-gray-200">
                  {{ item.text }}
              </option>
            </select>
            <input class="h-[30px] w-full p-1 text-sm border-2 border-gray-200 rounded-r-lg" type="search" :placeholder="t( 'Search bar' ) + '...'" v-model="searchedValue"/>
          </div>
          <div class="flex space-x-4 items-center accent-green-600/95 basis-1/2 text-center">
            <div class="flex items-center space-x-1 basis-1/3">
              <input type="radio" id="allImpactStudies" name="impactStudyFilter" @change="impactStudiesFilter = 'allImpactStudies'"
                class="w-5 h-5 cursor-pointer" checked/>
              <label for="allImpactStudies" class="cursor-pointer">{{ t( 'All impact studies' ) }}</label>
            </div>
            <div class="flex items-center space-x-1 basis-1/3">
              <input type="radio" id="linkedImpactStudies" name="impactStudyFilter" @change="impactStudiesFilter = 'linkedImpactStudies'"
                class="w-5 h-5 cursor-pointer"/>
              <label for="linkedImpactStudies" class="cursor-pointer">{{ t( 'Linked impact studies' ) }}</label>
            </div>
            <div class="flex items-center space-x-1 basis-1/3">
              <input type="radio" id="unLinkedImpactStudies" name="impactStudyFilter" @change="impactStudiesFilter = 'unLinkedImpactStudies'"
                class="w-5 h-5 cursor-pointer"/>
              <label for="unLinkedImpactStudies" class="cursor-pointer">{{ t( 'Unlinked impact studies' ) }}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Impact studies scroll list -->
      <div v-if="impactStudiesCheckList.length > 0" class="max-h-[400px] border border-2 border-gray-400 rounded overflow-auto">
        <RPImpactStudiesListItem v-for="impact_study in searchFilterImpactStudies()" :key="impact_study" :impact_study="impact_study" :readonly="readonly" @click="check"/>
      </div>

      <!-- Modal footer-->
      <div v-if="!readonly" class="flex space-x-4 justify-end">
        <div>
          <LoadingButton ref="saveBtn" type="submit" :label="t( 'Save' )" @click="save"/>
        </div>
        <div>
          <LoadingButton type="submit" :label="t( 'Cancel' )" @click="clickClose"/>
        </div>
      </div>
    </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import AXIOS from '@/features/axios.js';
  import FieldArea from '@/components/object/FieldArea.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import API from '@/constants/api.constants';
  import RPImpactStudiesListItem from '@/components/rp/RPImpactStudiesListItem.vue';

  const props = defineProps( {
    readonly: { Boolean, required: false, default: true },
    impact_studies: { Array, required: true, default: [] },
    action: { Object, required: true, default: {} }
  } );
  const emit = defineEmits( [ 'save', 'cancel' ] );

  const { t } = useI18n();
  const saveBtn = ref( null );
  const headers = [
    { text: t( 'Question' ), value: 'Question', sortable: true },
    { text: t( 'Answer' ), value: 'Answer', sortable: true },
  ];
  const searchedValue = ref( '' );
  const searchedField = ref( '' );
  const impactStudiesFilter = ref( '' );

  const selectChanged = ( event ) => {
    searchedField.value = event.target.value;
  };

  const searchFilterImpactStudies = () => {
    let result = impactStudiesCheckList.value;
    if ( searchedValue.value != '' ) {
      let search = searchedValue.value.toLowerCase();
      switch ( searchedField.value ) {
      case 'Question':
        result = impactStudiesCheckList.value.filter( ( impact_study ) =>
          ( impact_study.question ? String( impact_study.question ).toLowerCase().includes( search ) : false )
        );
        break;
      case 'Answer':
        result = impactStudiesCheckList.value.filter( ( impact_study ) =>
          ( impact_study.answer ? String( impact_study.answer ).toLowerCase().includes( search ) : false )
        );
        break;
      default:
        result = impactStudiesCheckList.value.filter( ( impact_study ) =>
          ( impact_study.question ? String( impact_study.question ).toLowerCase().includes( search ) : false )
          || ( impact_study.answer ? String( impact_study.answer ).toLowerCase().includes( search ) : false )
        );
        break;
      }
    }
    switch ( impactStudiesFilter.value ) {
    case 'linkedImpactStudies':
      result = result.filter( ( impact_study ) => ( impact_study ? impact_study.checked : false ) );
      break;
    case 'unLinkedImpactStudies':
      result = result.filter( ( impact_study ) => ( impact_study ? !impact_study.checked : false ) );
      break;
    default:
      break;
    }
    return result;
  };

  const impactStudiesCheckList = ref(
    props.impact_studies.map( ( impact_study ) => {
      if ( props.action.action_impact_studies && props.action.action_impact_studies.find( ( item ) => item.impact_study.id == impact_study.id ) != undefined ) {
        impact_study.checked = true;
      } else {
        impact_study.checked = false;
      }
      return impact_study;
    } ) );

  const check = ( impact_study ) => {
    if ( !props.readonly && impact_study != undefined && impact_study != null
      && impact_study.checked != undefined && impact_study.checked != null ) {
      impact_study.checked = !impact_study.checked;
    }
  };

  const clickClose = () => {
    emit( 'cancel' );
  };

  const save = () => {
    let promises = [];
    if ( props.action.id != undefined && props.action.id != null ) {
      let impactStudiesToUnlink = impactStudiesCheckList.value.filter( ( impact_study ) => {
        if ( !impact_study.checked && props.action.action_impact_studies
          && props.action.action_impact_studies.find( ( item ) => item.impact_study.id == impact_study.id ) != undefined ) {
          return impact_study.id;
        }
      } ).map( ( impact_study ) => impact_study.id );

      let impactStudiesToLink =  impactStudiesCheckList.value.filter( ( impact_study ) => {
        if ( impact_study.checked && ( ( props.action.action_impact_studies
          && props.action.action_impact_studies.find( ( item ) => item.impact_study.id == impact_study.id ) == undefined )
          || ( props.action.action_impact_studies == undefined || props.action.action_impact_studies == null ) ) ) {
          return impact_study.id;
        }
      } ).map( ( impactStudies ) => impactStudies.id );

      let url = API.get_api( API.API_NAMES.ACTIONS, props.action.id ) + 'impact_studies';

      if ( Array.isArray( impactStudiesToUnlink ) && impactStudiesToUnlink.length > 0 ) {
        promises.push( new Promise ( ( resolve ) => AXIOS.delete( url, { data: { id_impact_studies: impactStudiesToUnlink }, headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            if ( response.status == '201' ) {
              store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            }
            utils.showAxiosError( response );
            resolve();
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
            resolve();
          } ) ) );
      }

      if ( Array.isArray( impactStudiesToLink ) && impactStudiesToLink.length > 0 ) {
        promises.push( new Promise ( ( resolve ) => AXIOS.post( url, { id_impact_studies: impactStudiesToLink }, { headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            if ( response.status == '201' ) {
              store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            }
            utils.showAxiosError( response );
            resolve();
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
            resolve();
          } ) ) );
      }
    }

    Promise.all( promises )
      .then( () => {
        promises.length == 0 ? emit( 'save', false ) : emit( 'save', true );
        saveBtn.value.stopLoading();
      } );
  };
</script>