<template>
  <div class="space-y-5 text-xl">
    <Field :title="t( 'Title' )" :field="curFile.title" fkey="title"
      @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.DOCUMENT_TITLE"/>
    <Field :title="t( 'Description' )" :field="curFile.description" fkey="description"
      @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.DOCUMENT_DESCRIPTION"/>

    <div class="flex space-x-4 justify-end">
      <div>
        <LoadingButton :label="t( 'Save' )" @click="confirm"/>
      </div>
      <div>
        <LoadingButton :label="t( 'Cancel' )" @click="clickClose"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const props = defineProps( {
    file: { Object, require: true }
  } );

  const emit = defineEmits( [ 'confirm', 'cancel' ] );

  const curFile = ref( Object.assign( {}, props.file ) );

  const { t } = useI18n();

  const changeField = ( fkey, val ) => {
    curFile.value[ fkey ] = val;
  };

  const confirm = () => {
    emit( 'confirm', curFile.value );
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>